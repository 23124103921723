import './summary.css';
import { API } from "./../../services/api/api";
import { Badge, Card, CardContent, CardMedia, Chip, CircularProgress, styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import img_substance from './../../assets/ballon.png';
import img_flavor_ingredient from './../../assets/salade.png';
import img_ingredients from './../../assets/pile-de-boules.png';
import img_materials from './../../assets/enregistrer.png';
import img_aero_compounds from './../../assets/bulle.png';
import img_ncs from './../../assets/feuille_2.png';
import img_new from './../../assets/couche-plus.png';
// import img_tox_profile from './../../assets/elements.png';
import { countCategories } from "./../../utils/utils";
import CustomCardMedia from '../../features/custom_cardmedia/custom_cardmedia';

const style = {
  objectFit: 'contain',
  width: '65%',
  height: '65%',
  justifyContent: 'center',
  display: 'inline',
  paddingTop: '5%',
  opacity: "0.6",
  //paddingTop: '56.25%', // 16:9,
};


const style2 = {
  objectFit: 'contain',
  width: '30%',
  height: '30%',
  justifyContent: 'center',
  display: 'inline',
  paddingTop: '5%',
  opacity: "0.6",
  //paddingTop: '28.25%', // 4:3,
};

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -10,
    top: 40,
    // border: `4px solid ${theme.palette.background.paper}`,
    padding: '0 6px', // Increase padding to make the badge bigger
    fontSize: '0.95rem', // Increase font size to make the text bigger
    borderRadius: '12px', // Adjust border radius if needed
    backgroundColor: '#808080',
    color: '#fff'
  },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: '#808080', // Replace #808080 with the actual hex code of the flask icon color
  color: '#fff', // Adjust the text color if needed
  border: `1px solid ${theme.palette.background.paper}`,
  fontSize: '1rem', // Increase the font size
  // padding: '10px 12px', // Adjust padding if needed
}));


export default function Summary() {

  const [counters, setCounters] = React.useState({});
  const [loading, setLoading] = React.useState({});


  useEffect(() => {
    const fetchCounters = async () => {
      setLoading(loading => ({ ...loading, ...{ ['counters']: true } }));
      const result = await API.searchByCategory(null);
      setCounters(countCategories(result));
      setLoading(loading => ({ ...loading, ...{ ['counters']: false } }));

    };

    fetchCounters();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={3} md={3}>
        <Link to={'/search'} style={{ textDecoration: 'none' }} >
          <Card sx={{ flexGrow: 1 }} style={{ height: '100%' }}>
            <CardMedia
              component="img"
              image={img_substance}
              style={style}
            // title="all"               
            />
            <CardContent>
              {/* <StyledBadge badgeContent={!loading.counters ? (counters.sum ? counters.sum : 0) : <CircularProgress size="1rem" />} color="success" max={10000}> */}
                <Typography gutterBottom variant="h6" component="div">
                  Substances
                </Typography>
              {/* </StyledBadge> */}
              {/* <Typography variant="h5" color="text.secondary">
                  ( {!loading.counters ? (counters.sum ? counters.sum : 0) : <CircularProgress size="1rem"/> } )
                </Typography> */}
                <StyledChip label={!loading.counters ? (counters.sum ? counters.sum : 0) : <CircularProgress color="inherit" sx={{mt:1}} size="1rem"/> } variant="outlined" />

            </CardContent>
          </Card>
        </Link>
      </Grid>

      <Grid item xs={3} md={3}>
        <Stack spacing={2}>
          <Link to={'/search/Flavoring Ingredient'} style={{ textDecoration: 'none' }}>
            <Card sx={{ flexGrow: 1 }} style={{ height: '100%' }}>
              <CardMedia
                component="img"
                image={img_flavor_ingredient}
                style={style2}
              // title="po"
              />
              <CardContent>
                {/* <StyledBadge badgeContent={!loading.counters ? (counters['Flavoring Ingredient'] ? counters['Flavoring Ingredient'] : 0) : <CircularProgress size="1rem" />} color="success" max={10000}> */}
                  <Typography gutterBottom variant="h6" component="div">
                    Flavoring Ingredients
                  </Typography>
                {/* </StyledBadge> */}
                {/* <Typography variant="h5" color="text.secondary" noWrap>
                  ( {!loading.counters ? (counters['Flavoring Ingredient'] ? counters['Flavoring Ingredient'] : 0) : <CircularProgress size="1rem"/>} )
                </Typography> */}
                <StyledChip label={!loading.counters ? (counters['Flavoring Ingredient'] ? counters['Flavoring Ingredient'] : 0) : <CircularProgress color="inherit" sx={{mt:1}} size="1rem"/>} variant="outlined" />

              </CardContent>
            </Card>
          </Link>
          <Link to={'/search/Substrate Ingredient'} style={{ textDecoration: 'none' }}>
            <Card sx={{ flexGrow: 1 }} style={{ height: '100%' }}>
              <CardMedia
                component="img"
                image={img_ingredients}
                style={style2}
              // title="po"
              />
              <CardContent>
                {/* <StyledBadge badgeContent={!loading.counters ? (counters['Substrate Ingredient'] ? counters['Substrate Ingredient'] : 0) : <CircularProgress size="1rem" />} color="success" max={10000}> */}
                  <Typography gutterBottom variant="h6" component="div">
                    Substrate Ingredients
                  </Typography>
                {/* </StyledBadge> */}
                {/* <Typography variant="h5" color="text.secondary">
                ( {!loading.counters ? (counters['Substrate Ingredient'] ? counters['Substrate Ingredient'] : 0) : <CircularProgress size="1rem"/>} )
                </Typography> */}
                <StyledChip label={!loading.counters ? (counters['Substrate Ingredient'] ? counters['Substrate Ingredient'] : 0) : <CircularProgress color="inherit" sx={{mt:1}} size="1rem"/>} variant="outlined" />

              </CardContent>
            </Card>
          </Link>
        </Stack>
      </Grid>

      <Grid item xs={3} md={3}>
        <Stack spacing={2}>
          <Link to={'/search/Material'} style={{ textDecoration: 'none' }}>
            <Card sx={{ flexGrow: 1 }}>
              <CardMedia
                component="img"
                image={img_materials}
                style={style2}
              // title="po"
              />
              <CardContent>
                {/* <StyledBadge badgeContent={!loading.counters ? (counters.material ? counters.material : 0) : <CircularProgress size="1rem" />} color="success" max={10000}> */}
                  <Typography gutterBottom variant="h6" component="div">
                    Materials
                  </Typography>
                {/* </StyledBadge> */}
                {/* <Typography variant="h5" color="text.secondary">
                ( {!loading.counters ? (counters.material ? counters.material : 0) : <CircularProgress size="1rem"/>} )
                </Typography> */}
                <StyledChip label={!loading.counters ? (counters.material ? counters.material : 0) : <CircularProgress color="inherit" sx={{mt:1}} size="1rem"/>} variant="outlined" />

              </CardContent>
            </Card>
          </Link>
          <Link to={'/search/Aerosol Compound'} style={{ textDecoration: 'none' }}>
            <Card sx={{ flexGrow: 1 }}>
              <CardMedia
                component="img"
                image={img_aero_compounds}
                style={style2}
              // title="po"
              />
              <CardContent>
                {/* <StyledBadge badgeContent={!loading.counters ? (counters['Aerosol Compound'] ? counters['Aerosol Compound'] : 0) : <CircularProgress size="1rem" />} color="success" max={10000}> */}
                  <Typography gutterBottom variant="h6" component="div">
                    Aerosol Compounds
                  </Typography>
                {/* </StyledBadge> */}
                {/* <Typography variant="h5" color="text.secondary">
                  ( {!loading.counters ? (counters['Aerosol Compound'] ? counters['Aerosol Compound'] : 0) : <CircularProgress size="1rem"/>} )
                </Typography> */}
                <StyledChip label={!loading.counters ? (counters['Aerosol Compound'] ? counters['Aerosol Compound'] : 0) : <CircularProgress color="inherit" sx={{mt:1}} size="1rem"/>} variant="outlined" />

              </CardContent>
            </Card>
          </Link>
        </Stack>
      </Grid>

      <Grid item xs={3} md={3}>
        <Stack spacing={2}>
          <Link to={'/search/Natural Complex Substance'} style={{ textDecoration: 'none' }}>
            <Card sx={{ flexGrow: 1 }}>
              <CardMedia
                component="img"
                image={img_ncs}
                style={style2}
              // title="po"
              />
              <CardContent>
                {/* <StyledBadge badgeContent={!loading.counters ? (counters['Natural Complex Substance'] ? counters['Natural Complex Substance'] : 0) : <CircularProgress size="1rem" />} color="success" max={10000}> */}
                  <Typography gutterBottom variant="h6" component="div">
                    NCS
                  </Typography>
                {/* </StyledBadge> */}
                {/* <Typography variant="h5" color="text.secondary">
                ( {!loading.counters ? (counters['Natural Complex Substance'] ? counters['Natural Complex Substance'] : 0) : <CircularProgress size="1rem"/>} )
                </Typography> */}
                <StyledChip label={!loading.counters ? (counters['Natural Complex Substance'] ? counters['Natural Complex Substance'] : 0) : <CircularProgress color="inherit" sx={{mt:1}} size="1rem"/>} variant="outlined" />
              </CardContent>
            </Card>
          </Link>
          <Link to={'/ids'} style={{ textDecoration: 'none' }}>
            <Card sx={{ flexGrow: 1 }}>
              <CardMedia
                component="img"
                image={img_new}
                style={style2}
              // title="po"
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  Add New Substance
                </Typography>
                {/* <Typography variant="h5" color="text.secondary">
                  (+)
                </Typography> */}
                <StyledChip label="+" variant="outlined" />
              </CardContent>
            </Card>
          </Link>
        </Stack>
      </Grid>
    </Grid>
  );
}