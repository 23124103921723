import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { saveAs } from 'file-saver';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import Docxtemplater from 'docxtemplater';
import { toast } from 'react-toastify';
import { getObjectValueByKey, isNCS, isNullUndefinedEmpty, parseSmallDate } from '../utils/utils';
import { calculateADE, calculateFinalMRL, calculateLocalSensitizationMRL, calculateModifiedPoD, calculateMRL, calculateOverallUF, calculateUFA } from '../utils/calculation';


const SummaryButton = ({ values,
    population_concerned,
    platforms,
    animals,
    animal_route_administrations,
    study_test_methods,
    study_durations,
    human_route_exposures,
    uncertainty_factors,
    severity,
    containers }) => {

    const [open, setOpen] = React.useState(false);
    const [type_report, setTypeReport] = React.useState("");

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleNoSelection = () => {
        toast.warn("Please select a report to generate !");
    }

    const isEmptyObject = (object) => {
        return Object.keys(object).length === 0;
    };

    const handleReportType = (event) => {
        setTypeReport(event.target.value);
    };

    const floorValue = (value) => {
        if (value > 100000) {
            return Math.floor(value / 10000) * 10000;
        } else if (value > 10000) {
            return Math.floor(value / 1000) * 1000;
        } else if (value > 1000) {
            return Math.floor(value / 100) * 100;
        } else if (value > 100) {
            return Math.floor(value / 10) * 10;
        } else if (value > 10) {
            return Math.floor(value / 1) * 1;
        } else if (value < 1) {
            return value.toFixed(2);
        }

        return value;
    }

    const loadFile = (url) => {
        return new Promise((resolve, reject) => {
            PizZipUtils.getBinaryContent(url, (error, content) => {
                if (error) {
                    reject(error);
                } else {
                    resolve(content);
                }
            });
        });
    };

    const handleDownloadReport = async () => {

        try {

            const basePath = type_report === "summary" ? "/Toxicological_Information_Summary.docx" : type_report === "review_inhalation" ? "/Report_Toxicological_Risk_Assessment_DS_Review_Inhalation.docx" : "/Report_Toxicological_Risk_Assessment_DS_Review_Oral.docx";
            const content = await loadFile(basePath);

            if (!content) {
                toast.warn("Content not fully loaded, please retry!");
                return;
            }

            const zip = new PizZip(content);
            const doc = new Docxtemplater(zip, {
                paragraphLoop: true,
                linebreaks: true,
            });
            
            
            let conditional_object = {}
            conditional_object.isNCS = isNCS(values.categories)

            if (type_report === "summary") {

                doc.render({
                    // Conditional 
                    ...conditional_object,

                    // Generic
                    CHEM_NAME: values.name,
                    CAS_NUMBER: values.cas,
                    IUPAC_NAME: values.IUPAC_name,
                    KEY_SYNONYMS: Array.isArray(values.synonyms) ? values.synonyms.join(";") : values.synonyms,
                    ALTERNATIVE_CAS: Array.isArray(values.alternative_cas) ? values.alternative_cas.join(";") : values.alternative_cas,
                    CATEGORIES: Array.isArray(values.categories) ? values.categories.join(";") : values.categories,
                    MOLECULAR_FORMULA: values.chem_id.mol_formula,
                    MOLECULAR_WEIGHT: values.chem_id.mol_weight,
                    CANONICAL_SMILES: values.chem_id.canonical_smiles,
                    FEMA_NUMBER: values.chem_id.FEMA_number,
                    FEMA_SAFETY_STATUS: values.chem_id.FEMA_safety_status,
                    FL_NUMBER: values.chem_id.FL_number,
                    JECFA_NUMBER: values.chem_id.JECFA_number,
                    JECFA_SAFETY_STATUS: values.chem_id.JECFA_status,
                    BOT_NAME: values.chem_id.botanical_name,
                    PLANT_FAMILY: values.chem_id.plant_family_name,
                    BOT_CAT: values.chem_id.botanical_category,
                    NCS_PROCESS: values.chem_id.ncs_processing,
                    COMPO_REF: values.composition_source,
                    FOOD_CONTACT_STATUS: values.chem_id.food_contact_status,
                    RESPIRATORY_TRACT_IRRITATION: values.tox_data.local_effect.respiratory_tract_irritation,
                    SKIN_IRRITATION: values.tox_data.local_effect.skin_irritation,
                    A_D_M_E: values.tox_data.ADME,
                    SKIN_SENSITIZATION: values.tox_data.sensitization_tolerance.skin_sensitization,
                    SKIN_SENS_ALERT: values.tox_data.sensitization_tolerance.skin_sensitization_alert,
                    EYE_IRRITATION: values.tox_data.local_effect.eye_irritation,
                    OTHER_LOCAL_EFFECTS: values.tox_data.local_effect.other_local_effect,
                    RESPIRATORY_SENSITIZATION: values.tox_data.sensitization_tolerance.respiratory_sensitization,
                    RESP_SENS_ALERT: values.tox_data.sensitization_tolerance.respiratory_sensitization_alert,
                    ORAL_ALLERGY_INTOLERANCE: values.tox_data.sensitization_tolerance.oral_allergy_intolerance,
                    IFRA_STATUS: values.tox_data.sensitization_tolerance.IFRA_status,
                    IFRA_RECOMMENDATION_COMMENTS: values.tox_data.sensitization_tolerance.IFRA_recommendation_comment,
                    IFRA_CAT_1: values.tox_data.sensitization_tolerance.IFRA_cat_1,
                    IFRA_CAT_6: values.tox_data.sensitization_tolerance.IFRA_cat_6,
                    CMR_ALERTS: values.tox_data.general_toxicity.CMR_alerts,
                    ACUTE_TOXICITY: values.tox_data.general_toxicity.acute_toxicity,
                    R_D_T_INHALATION: values.tox_data.general_toxicity.repeated_dose_toxicity.inhalation,
                    R_D_T_ORAL: values.tox_data.general_toxicity.repeated_dose_toxicity.oral,
                    R_D_T_DERMAL: values.tox_data.general_toxicity.repeated_dose_toxicity.dermal,
                    G_ENOTOXICITY: values.tox_data.general_toxicity.genotoxicity,
                    C_ARCINOGENICITY: values.tox_data.general_toxicity.carcinogenicity,
                    REPRODUCTIVE_DEVELOPMENTAL_TOXICITY: values.tox_data.general_toxicity.reproductive_developmental_toxicity,
                    CARDIOPULMONARY_EFFECTS: values.tox_data.general_toxicity.cardiopulmonary_effects,
                    IN_SILICO_DATA: values.tox_data.in_silico_data,
                    OTHER_RELEVANT_TOXICITY: values.tox_data.general_toxicity.other_revelant_toxicity,
                    TOX_PROFILE_COMMENT: values.tox_data.comment,
                    A_DDICTIVENESS: values.tox_data.addictivness_attractiveness,
                    CLP_HARMONIZED_CLASSIFICATION: values.chem_id.CLP_health_harmo_class,
                    IARC_CLASSIFICATION: values.chem_id.IARC_class,
                    NTP_CLASSIFICATION: values.chem_id.NTP_class,
                    PROP_65: values.chem_id.prop_65,
                    BOILING_POINT: values.chem_id.boiling_point,
                    CRAMERCLASS_REFERENCE: values.chem_id.CRAMER_class_ref,
                    CRAMER_CLASS: values.chem_id.CRAMER_class,
                    NESIL_VALUE: values.tox_data.sensitization_tolerance.NESIL,
                    NESIL_COMMENTS: values.tox_data.sensitization_tolerance.NESIL_comment,
                    SCENIHR: values.tox_data.SCENIHR,

                    // NCS Specific 
                    CONTAINERS: values.containers,
                });

            } else {

                let review_array = [];
                let platform_array = [];
                let type = type_report.split("_")[1];
                let type_string = type_report.split("_")[1] + "_exposure";

                if (type_report === "review_inhalation") {
                    platforms.filter(x => x.inhalation === true).forEach(platform => {
                        platform_array.push(platform.name);

                        review_array.push({
                            "name": platform.name,
                            "sys_mrl": calculateMRL("inhalation",
                                values,
                                platforms,
                                platform.name,
                                animals,
                                population_concerned,
                                "general",
                                animal_route_administrations,
                                human_route_exposures,
                                uncertainty_factors,
                                containers).toFixed(2),
                            "local_mrl": calculateLocalSensitizationMRL("inhalation",
                                values,
                                platforms,
                                platform.name,
                                containers).toFixed(2),
                            "final_mrl": calculateFinalMRL("inhalation",
                                values,
                                platforms,
                                platform.name,
                                animals,
                                population_concerned,
                                "general",
                                animal_route_administrations,
                                human_route_exposures,
                                uncertainty_factors,
                                containers).toFixed(2),
                            "shared_mrl": floorValue(calculateFinalMRL("inhalation",
                                values,
                                platforms,
                                platform.name,
                                animals,
                                population_concerned,
                                "general",
                                animal_route_administrations,
                                human_route_exposures,
                                uncertainty_factors,
                                containers)),
                        });
                    });
                } else {
                    platforms.filter(x => x.oral === true).forEach(platform => {
                        platform_array.push(platform.name);

                        review_array.push({
                            "name": platform.name,
                            "sys_mrl": calculateMRL("oral",
                                values,
                                platforms,
                                platform.name,
                                animals,
                                population_concerned,
                                "general",
                                animal_route_administrations,
                                human_route_exposures,
                                uncertainty_factors,
                                containers).toFixed(2),
                            "local_mrl": calculateLocalSensitizationMRL("oral",
                                values,
                                platforms,
                                platform.name,
                                containers).toFixed(2),
                            "final_mrl": calculateFinalMRL("oral",
                                values,
                                platforms,
                                platform.name,
                                animals,
                                population_concerned,
                                "general",
                                animal_route_administrations,
                                human_route_exposures,
                                uncertainty_factors,
                                containers).toFixed(2),
                            "shared_mrl": floorValue(calculateFinalMRL("oral",
                                values,
                                platforms,
                                platform.name,
                                animals,
                                population_concerned,
                                "general",
                                animal_route_administrations,
                                human_route_exposures,
                                uncertainty_factors,
                                containers)),
                        });
                    });
                }

                doc.render({
                    // Conditionnal
                    ...conditional_object,

                    // Generic
                    CHEM_NAME: values.name,
                    CAS_NUMBER: values.cas,
                    APPROVAL_STATUS: values.approval_status,
                    BOT_NAME: values.chem_id.botanical_name,
                    PLANT_FAMILY: values.chem_id.plant_family_name,
                    BOT_CAT: values.chem_id.botanical_category,
                    NCS_PROCESS: values.chem_id.ncs_processing,
                    COMPO_REF: values.composition_source,
                    RESPIRATORY_TRACT_IRRITATION: values.tox_data.local_effect.respiratory_tract_irritation,
                    SKIN_IRRITATION: values.tox_data.local_effect.skin_irritation,
                    A_D_M_E: values.tox_data.ADME,
                    SKIN_SENSITIZATION: values.tox_data.sensitization_tolerance.skin_sensitization,
                    SKIN_SENS_ALERT: values.tox_data.sensitization_tolerance.skin_sensitization_alert,
                    EYE_IRRITATION: values.tox_data.local_effect.eye_irritation,
                    OTHER_LOCAL_EFFECTS: values.tox_data.local_effect.other_local_effect,
                    RESPIRATORY_SENSITIZATION: values.tox_data.sensitization_tolerance.respiratory_sensitization,
                    RESPIRATORY_SENS_ALERT: values.tox_data.sensitization_tolerance.respiratory_sensitization_alert,
                    ORAL_ALLERGY_INTOLERANCE: values.tox_data.sensitization_tolerance.oral_allergy_intolerance,
                    IFRA_STATUS: values.tox_data.sensitization_tolerance.IFRA_status,
                    IFRA_RECOMMENDATION_COMMENTS: values.tox_data.sensitization_tolerance.IFRA_recommendation_comment,
                    IFRA_CAT_1: values.tox_data.sensitization_tolerance.IFRA_cat_1,
                    IFRA_CAT_6: values.tox_data.sensitization_tolerance.IFRA_cat_6,
                    CMR_ALERTS: values.tox_data.general_toxicity.CMR_alerts,
                    ACUTE_TOXICITY: values.tox_data.general_toxicity.acute_toxicity,
                    R_D_T_INHALATION: values.tox_data.general_toxicity.repeated_dose_toxicity.inhalation,
                    R_D_T_ORAL: values.tox_data.general_toxicity.repeated_dose_toxicity.oral,
                    R_D_T_DERMAL: values.tox_data.general_toxicity.repeated_dose_toxicity.dermal,
                    G_ENOTOXICITY: values.tox_data.general_toxicity.genotoxicity,
                    C_ARCINOGENICITY: values.tox_data.general_toxicity.carcinogenicity,
                    REPRODUCTIVE_DEVELOPMENTAL_TOXICITY: values.tox_data.general_toxicity.reproductive_developmental_toxicity,
                    CARDIOPULMONARY_EFFECTS: values.tox_data.general_toxicity.cardiopulmonary_effects,
                    IN_SILICO_DATA: values.tox_data.in_silico_data,
                    OTHER_RELEVANT_TOXICITY: values.tox_data.general_toxicity.other_revelant_toxicity,
                    TOX_PROFILE_COMMENT: values.tox_data.comment,
                    A_DDICTIVENESS: values.tox_data.addictivness_attractiveness,
                    CLP_HARMONIZED_CLASSIFICATION: values.chem_id.CLP_health_harmo_class,
                    IARC_CLASSIFICATION: values.chem_id.IARC_class,
                    NTP_CLASSIFICATION: values.chem_id.NTP_class,
                    PROP_65: values.chem_id.prop_65,
                    MOLECULAR_WEIGHT: values.chem_id.mol_weight,
                    BOILING_POINT: values.chem_id.boiling_point,
                    CRAMERCLASS_REFERENCE: values.chem_id.CRAMER_class_ref,
                    CRAMER_CLASS: values.chem_id.CRAMER_class,
                    NESIL_VALUE: values.tox_data.sensitization_tolerance.NESIL,
                    NESIL_COMMENTS: values.tox_data.sensitization_tolerance.NESIL_comment,

                    // NCS Specific 
                    CONTAINERS: values.containers,

                    // Method dependent 
                    POD_DOSE_DESCRIPTOR: values.tox_data[type_string]?.toxicity.POD.dose_descriptor,
                    POD_VALUE: values.tox_data[type_string]?.toxicity.POD.value,
                    POD_UNIT: values.tox_data[type_string]?.toxicity.POD.unit,
                    GLP_COMPLIANCE: values.tox_data[type_string]?.toxicity.POD.GLP_compliance,
                    STUDY_REFERENCE: values.tox_data[type_string]?.toxicity.POD.study_ref,
                    MATERIAL_NAME: values.tox_data[type_string]?.toxicity.POD.read_across.material_name,
                    MATERIAL_CAS_NO: values.tox_data[type_string]?.toxicity.POD.read_across.material_cas,
                    READ_ACROSS_MATERIAL_NAME: values.tox_data[type_string]?.toxicity.POD.read_across.material_name,
                    READ_ACROSS_MATERIAL_CAS_NO: values.tox_data[type_string]?.toxicity.POD.read_across.material_cas,
                    READ_ACROSS_MOL_WEIGHT: values.tox_data[type_string]?.toxicity.POD.read_across.material_mw,
                    TYPE_OF_EFFECT: values.tox_data[type_string]?.toxicity.POD.type_effect,
                    EFFECTS_DESCRIPTION: values.tox_data[type_string]?.toxicity.POD.effects_description,
                    EFFECTS_SEVERITY: severity.find(x => x.name === values.tox_data[type_string]?.toxicity.POD.effects_severity)?.label,
                    EXPOSURE_ANIMAL_HOURS_DAY: values.tox_data[type_string]?.toxicity.POD.exposure_hours_per_day,
                    EXPOSURE_ANIMAL_DAYS_WK: values.tox_data[type_string]?.toxicity.POD.exposure_day_per_week,
                    UFDI: values.tox_data[type_string]?.toxicity.uncertainty_factors.UFDi,
                    UFD_I_COMMENTS: values.tox_data[type_string]?.toxicity.uncertainty_factors.UFDi_comments,
                    UFD_II: values.tox_data[type_string]?.toxicity.uncertainty_factors.UFDii,
                    COMMENTS_UFD_I_I: values.tox_data[type_string]?.toxicity.uncertainty_factors.UFDii_comments,
                    APPLICABLE_TTC: values.tox_data[type_string]?.applicable_TTC,

                    STUDY_TEST_METHOD: study_test_methods.find(o => o.name === values.tox_data[type_string]?.toxicity.POD.study_test_method)?.label,
                    ANIMAL: animals.find(o => o.name === values.tox_data[type_string]?.toxicity.POD.animals)?.label,
                    ALLOMETRIC_SCALING: animals.find(o => o.name === values.tox_data[type_string]?.toxicity.POD.animals)?.allometric_scaling,
                    STANDARD_RESPIRATORY_VOLUME: animals.find(o => o.name === values.tox_data[type_string]?.toxicity.POD.animals)?.standard_respiratory_volume,
                    ANI_ROUTE_ADMINISTRATION: animal_route_administrations.find(o => o.name === values.tox_data[type_string]?.toxicity.POD.animal_route_administration)?.label,
                    ABS_POD: animal_route_administrations.find(o => o.name === values.tox_data[type_string]?.toxicity.POD.animal_route_administration)?.value,
                    ABS_HUMAN: human_route_exposures.find(o => o.name === type)?.value,
                    STUDY_DURATION: study_durations.find(o => o.name === values.tox_data[type_string]?.toxicity.POD.study_duration)?.label,

                    // Method specific
                    ADI_VALUE: values.tox_data.oral_exposure.ADI,
                    ADI_REF: values.tox_data.oral_exposure.ADI_ref,

                    // Population Concerned 
                    HUM_INH_RATE: population_concerned.find(x => x.name === "general")?.human_daily_inhalation_rate,
                    POPULATION_BODYWEIGHT: population_concerned.find(x => x.name === "general")?.human_body_weight,
                    POPULATION_CONCERNED: population_concerned.find(x => x.name === "general")?.label,
                    POPULATION_EXPOSURE_DAY: population_concerned.find(x => x.name === "general")?.human_exposure_per_day,
                    POPULATION_EXPOSURE_WK: population_concerned.find(x => x.name === "general")?.human_exposure_per_week,

                    UFA: calculateUFA(type, values, uncertainty_factors, animals),
                    UFH: getObjectValueByKey(uncertainty_factors.find(o => o.name === "UFH"), population_concerned.find(x => x.name === "general")?.name),
                    UFS: getObjectValueByKey(uncertainty_factors.find(o => o.name === "UFS"), values.tox_data[type]?.toxicity.POD.study_duration),
                    UFL: getObjectValueByKey(uncertainty_factors.find(o => o.name === "UFL"),
                        values.tox_data[type]?.toxicity.POD.dose_descriptor === "LOAEL" ? (values.tox_data[type]?.toxicity.POD.dose_descriptor + "_" + values.tox_data[type]?.toxicity.POD.effects_severity) : values.tox_data[type]?.toxicity.POD.dose_descriptor
                    ),

                    OVERALL_UF: calculateOverallUF(type,
                        values,
                        uncertainty_factors,
                        "general",
                        animals),

                    MODIFIED_POD: calculateModifiedPoD(type,
                        values,
                        population_concerned,
                        "general",
                        animals,
                        animal_route_administrations,
                        human_route_exposures).toFixed(2),

                    ADE_VALUE: calculateADE(type,
                        values,
                        population_concerned,
                        "general",
                        animal_route_administrations,
                        human_route_exposures,
                        uncertainty_factors,
                        animals).toFixed(2),

                    // Arrays 
                    platform_inhalation: platform_array,
                    platform_inhalation_results: review_array,
                });
            }

            const out = doc.getZip().generate({
                type: 'blob',
                mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            });

            const basename = type_report === "summary" ? "Toxicological_Information_Summary_" : type_report === "review_inhalation" ? "Toxicological_Risk_Assessment_DS_Review_Inhalation_" : "Toxicological_Risk_Assessment_DS_Review_Oral_";
            const filename = "Report_" + basename + values.cas + "_" + parseSmallDate(Date.now()) + ".docx";
            saveAs(out, filename);

        } catch (ex) {
            console.log(ex);
            toast.error("Error during report generation ! Please contact an administrator.");
        } finally {
            setOpen(false);
        }
    };


    return (
        <>
            {
                !isNullUndefinedEmpty(values) && !isEmptyObject(values) ? (
                    <>
                        <Tooltip title="Download TIS/TRA_DS Review report" placement="top" arrow>
                            <IconButton aria-label="download" onClick={() => { handleOpen() }}>
                                <DownloadIcon />
                            </IconButton>
                        </Tooltip>
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            PaperProps={{
                                sx: { minWidth: "400px" }
                            }}
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Select a report"}</DialogTitle>
                            <DialogContent>
                                <FormControl fullWidth sx={{ mt: 2 }}>
                                    <InputLabel id="report_type">Report Type</InputLabel>
                                    <Select
                                        labelId="report_type"
                                        id="report_type"
                                        name="report_type"
                                        value={type_report}
                                        onChange={handleReportType}
                                    >
                                        <MenuItem value={"summary"}>Toxicological Information Summary</MenuItem>
                                        <MenuItem value={"review_inhalation"}>TRA Report_Inhalation_DS Review</MenuItem>
                                        {/* <MenuItem value={"review_oral"}>Report TRA_DS Oral Review</MenuItem> */}
                                    </Select>
                                </FormControl>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary">
                                    Cancel
                                </Button>
                                <Button onClick={() => { !isNullUndefinedEmpty(type_report) ? handleDownloadReport() : handleNoSelection() }} color="secondary" autoFocus>
                                    Save
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                ) : null
            }
        </>
    );
};

export default SummaryButton;