import { getObjectValueByKey, isNullUndefinedEmpty } from "./utils";

/**
 * Calculates the modified PoD value.
 *
 * @param {string} type - The type of exposure (inhalation or oral).
 * @param {object} object - The object containing toxicity data.
 * @param {array} population_concerned - Array of population concerned objects.
 * @param {string} pop_conc - The population concerned name.
 * @param {array} animals - Array of animal objects.
 * @param {array} animal_route_administrations - Array of animal route administration objects.
 * @param {array} human_route_exposure - Array of human route exposure objects.
 * @return {number} - The modified PoD value.
 */
export function calculateModifiedPoD(type, object, population_concerned, pop_conc, animals, animal_route_administrations, human_route_exposure) {
    if (type === "inhalation") {
        let pod_value = parseFloat(object.tox_data.inhalation_exposure.toxicity.POD.value);
        let unit = object.tox_data.inhalation_exposure.toxicity.POD.unit;
        let s_r_f = parseFloat(animals.find(o => o.name === object.tox_data.inhalation_exposure.toxicity.POD.animals)?.standard_respiratory_volume);
        let expo_h_p_d = parseFloat(object.tox_data.inhalation_exposure.toxicity.POD.exposure_hours_per_day);
        let expo_d_p_w = parseFloat(object.tox_data.inhalation_exposure.toxicity.POD.exposure_day_per_week);
        let human_expo_day = parseFloat(population_concerned.find(o => o.name === pop_conc)?.human_exposure_per_day);
        let human_expo_week = parseFloat(population_concerned.find(o => o.name === pop_conc)?.human_exposure_per_week);
        let animal_r_a = parseFloat(animal_route_administrations.find(o => o.name === object.tox_data.inhalation_exposure.toxicity.POD.animal_route_administration)?.value);
        let human_route_expo = parseFloat(human_route_exposure.find(o => o.name === type)?.value);

        return pod_value * (unit === "mg/kgBW/day" ? 1 / (s_r_f * (1440 / 1)) : 1) * (expo_h_p_d / human_expo_day) * (expo_d_p_w / human_expo_week) * (animal_r_a / human_route_expo);
    } else {
        let pod_value = parseFloat(object.tox_data.oral_exposure.toxicity.POD.value);
        let unit = object.tox_data.oral_exposure.toxicity.POD.unit;
        let s_r_f = parseFloat(animals.find(o => o.name === object.tox_data.oral_exposure.toxicity.POD.animals)?.standard_respiratory_volume);
        let expo_h_p_d = parseFloat(object.tox_data.oral_exposure.toxicity.POD.exposure_hours_per_day);
        let expo_d_p_w = parseFloat(object.tox_data.oral_exposure.toxicity.POD.exposure_day_per_week);
        let human_expo_day = parseFloat(population_concerned.find(o => o.name === pop_conc)?.human_exposure_per_day);
        let human_expo_week = parseFloat(population_concerned.find(o => o.name === pop_conc)?.human_exposure_per_week);
        let animal_r_a = parseFloat(animal_route_administrations.find(o => o.name === object.tox_data.oral_exposure.toxicity.POD.animal_route_administration)?.value);
        let human_route_expo = parseFloat(human_route_exposure.find(o => o.name === type)?.value);

        return pod_value * (expo_h_p_d / human_expo_day) * (expo_d_p_w / human_expo_week) * (animal_r_a / human_route_expo);
    }
}

/**
 * Calculates the ADE value.
 *
 * @param {string} type - The type of exposure (inhalation or oral).
 * @param {object} object - The object containing toxicity data.
 * @param {array} population_concerned - Array of population concerned objects.
 * @param {string} pop_conc - The population concerned name.
 * @param {array} animal_route_administrations - Array of animal route administration objects.
 * @param {array} human_route_exposure - Array of human route exposure objects.
 * @param {array} uncertainty_factors - Array of uncertainty factor objects.
 * @param {array} animals - Array of animal objects.
 * @return {number} - The ADE value.
 */
export function calculateADE(type, object, population_concerned, pop_conc, animal_route_administrations, human_route_exposure, uncertainty_factors, animals) {
    if (type === "inhalation") {
        let unit = object.tox_data.inhalation_exposure.toxicity.POD.unit;
    } else {
        let unit = object.tox_data.oral_exposure.toxicity.POD.unit;
    }

    let human_d_i_r = parseFloat(population_concerned.find(o => o.name === pop_conc)?.human_daily_inhalation_rate);
    let human_b_w = parseFloat(population_concerned.find(o => o.name === pop_conc)?.human_body_weight);

    return (calculateModifiedPoD(type, object, population_concerned, pop_conc, animals, animal_route_administrations, human_route_exposure) / calculateOverallUF(type, object, uncertainty_factors, pop_conc, animals)) * (type === "inhalation" ? human_d_i_r : human_b_w);
}

/**
 * Calculates the individual composition modified PoD value.
 *
 * @param {string} type - The type of exposure (inhalation or oral).
 * @param {object} object - The object containing toxicity data.
 * @param {array} population_concerned - Array of population concerned objects.
 * @param {string} pop_conc - The population concerned name.
 * @param {array} animals - Array of animal objects.
 * @param {array} animal_route_administrations - Array of animal route administration objects.
 * @param {array} human_route_exposure - Array of human route exposure objects.
 * @return {number} - The individual composition modified PoD value.
 */
export function calculateIndividualCompositionModifiedPoD(type, object, population_concerned, pop_conc, animals, animal_route_administrations, human_route_exposure) {
    if (!isNullUndefinedEmpty(object)) {
        if (type === "inhalation") {
            let pod_value = parseFloat(object.inhalation_exposure.pod_value);
            let unit = object.inhalation_exposure.unit;
            let s_r_f = parseFloat(animals.find(o => o.name === object.inhalation_exposure.animal)?.standard_respiratory_volume);
            let expo_h_p_d = parseFloat(object.inhalation_exposure.exposure_hours_per_day);
            let expo_d_p_w = parseFloat(object.inhalation_exposure.exposure_day_per_week);
            let human_expo_day = parseFloat(population_concerned.find(o => o.name === "general")?.human_exposure_per_day);
            let human_expo_week = parseFloat(population_concerned.find(o => o.name === "general")?.human_exposure_per_week);
            let animal_r_a = parseFloat(animal_route_administrations.find(o => o.name === object.inhalation_exposure.animal_route_administration)?.value);
            let human_route_expo = parseFloat(human_route_exposure.find(o => o.name === type)?.value);

            return pod_value * (unit === "mg/kgBW/day" ? 1 / (s_r_f * (1440 / 1)) : 1) * (expo_h_p_d / human_expo_day) * (expo_d_p_w / human_expo_week) * (animal_r_a / human_route_expo);
        } else {
            let pod_value = parseFloat(object.oral_exposure.pod_value);
            let unit = object.oral_exposure.unit;
            let s_r_f = parseFloat(animals.find(o => o.name === object.oral_exposure.animals)?.standard_respiratory_volume);
            let expo_h_p_d = parseFloat(object.oral_exposure.exposure_hours_per_day);
            let expo_d_p_w = parseFloat(object.oral_exposure.exposure_day_per_week);
            let human_expo_day = parseFloat(population_concerned.find(o => o.name === "general")?.human_exposure_per_day);
            let human_expo_week = parseFloat(population_concerned.find(o => o.name === "general")?.human_exposure_per_week);
            let animal_r_a = parseFloat(animal_route_administrations.find(o => o.name === object.oral_exposure.animal_route_administration)?.value);
            let human_route_expo = parseFloat(human_route_exposure.find(o => o.name === type)?.value);

            return pod_value * (expo_h_p_d / human_expo_day) * (expo_d_p_w / human_expo_week) * (animal_r_a / human_route_expo);
        }
    }
}

/**
 * Calculates the individual composition ADE value.
 *
 * @param {number} modified_pod - The modified PoD value.
 * @param {number} overall_uf - The overall uncertainty factor.
 * @param {number} human_daily_inhalation_rate - The human daily inhalation rate.
 * @return {number} - The individual composition ADE value.
 */
export function calculIndividualCompositionADE(modified_pod, overall_uf, human_daily_inhalation_rate) {
    return (modified_pod / overall_uf) * human_daily_inhalation_rate;
}

/**
 * Calculates the composition ADE value.
 *
 * @param {string} type - The type of exposure (inhalation or oral).
 * @param {object} object - The object containing toxicity data.
 * @param {array} cont - Array of container objects.
 * @param {array} animals - Array of animal objects.
 * @param {array} population_concerned - Array of population concerned objects.
 * @param {array} animal_route_administrations - Array of animal route administration objects.
 * @param {array} human_route_exposure - Array of human route exposure objects.
 * @param {array} uncertainty_factors - Array of uncertainty factor objects.
 * @return {number} - The composition ADE value.
 */
export function calculateCompositionADE(type, object, cont, animals, population_concerned, animal_route_administrations, human_route_exposure, uncertainty_factors) {
    if (type === "inhalation") {
        let pod_value = parseFloat(cont.find(x => x._key === object.containers.find(y => y.is_ref === true)?._key)?.inhalation_exposure?.pod_value);
        let unit = cont.find(x => x._key === object.containers.find(y => y.is_ref === true)?._key)?.inhalation_exposure?.unit;
        let s_r_f = parseFloat(animals.find(o => o.name === cont.find(x => x._key === object.containers.find(y => y.is_ref === true)?._key)?.inhalation_exposure?.animal)?.standard_respiratory_volume);
        let expo_h_p_d = parseFloat(cont.find(x => x._key === object.containers.find(y => y.is_ref === true)?._key)?.inhalation_exposure?.exposure_hours_per_day);
        let expo_d_p_w = parseFloat(cont.find(x => x._key === object.containers.find(y => y.is_ref === true)?._key)?.inhalation_exposure?.exposure_day_per_week);
        let human_expo_day = parseFloat(population_concerned.find(o => o.name === "general")?.human_exposure_per_day);
        let human_expo_week = parseFloat(population_concerned.find(o => o.name === "general")?.human_exposure_per_week);
        let animal_r_a = parseFloat(animal_route_administrations.find(o => o.name === cont.find(x => x._key === object.containers.find(y => y.is_ref === true)?._key)?.inhalation_exposure?.animal_route_administration)?.value);
        let human_route_expo = parseFloat(human_route_exposure.find(o => o.name === "inhalation")?.value);
        let ufa = parseFloat(uncertainty_factors.find(o => o.name === "UFA constant")?.inhalation_value);
        let ufh = parseFloat(getObjectValueByKey(uncertainty_factors.find(o => o.name === "UFH"), "general"));
        let ufs = parseFloat(getObjectValueByKey(uncertainty_factors.find(o => o.name === "UFS"), cont.find(x => x._key === object.containers.find(y => y.is_ref === true)?._key)?.inhalation_exposure?.study_duration));
        let ufl = parseFloat(getObjectValueByKey(uncertainty_factors.find(o => o.name === "UFL"), cont.find(x => x._key === object.containers.find(y => y.is_ref === true)?._key)?.inhalation_exposure?.dose_descriptor === "LOAEL" ? (cont.find(x => x._key === object.containers.find(y => y.is_ref === true)?._key)?.inhalation_exposure?.dose_descriptor + "_" + cont.find(x => x._key === object.containers.find(y => y.is_ref === true)?._key)?.inhalation_exposure?.effects_severity) : cont.find(x => x._key === object.containers.find(y => y.is_ref === true)?._key)?.inhalation_exposure?.dose_descriptor));
        let ufdi = parseFloat(cont.find(x => x._key === object.containers.find(y => y.is_ref === true)?._key)?.inhalation_exposure?.UFDi);
        let ufdii = parseFloat(cont.find(x => x._key === object.containers.find(y => y.is_ref === true)?._key)?.inhalation_exposure?.UFDii);
        let human_d_i_r = parseFloat(population_concerned.find(o => o.name === "general")?.human_daily_inhalation_rate);
        let percentage = parseFloat(object.containers.find(y => y.is_ref === true)?.percentage);

        let modified_pod = (pod_value * (unit === "mg/kgBW/day" ? 1 / (s_r_f * (1440 / 1)) : 1) * (expo_h_p_d / human_expo_day) * (expo_d_p_w / human_expo_week) * (animal_r_a / human_route_expo));
        let overall_uf = (ufa * ufh * ufs * ufl * (ufdi * ufdii));
        let h_d_i_r = ((human_d_i_r) * (100 / percentage));

        return calculIndividualCompositionADE(modified_pod, overall_uf, h_d_i_r);
    } else {
        let pod_value = parseFloat(cont.find(x => x._key === object.containers.find(y => y.is_ref === true)?._key)?.oral_exposure?.pod_value);
        let unit = cont.find(x => x._key === object.containers.find(y => y.is_ref === true)?._key)?.oral_exposure?.unit;
        let s_r_f = parseFloat(animals.find(o => o.name === cont.find(x => x._key === object.containers.find(y => y.is_ref === true)?._key)?.oral_exposure?.animal)?.standard_respiratory_volume);
        let expo_h_p_d = parseFloat(cont.find(x => x._key === object.containers.find(y => y.is_ref === true)?._key)?.oral_exposure?.exposure_hours_per_day);
        let human_expo_day = parseFloat(population_concerned.find(o => o.name === "general")?.human_exposure_per_day);
        let expo_d_p_w = parseFloat(cont.find(x => x._key === object.containers.find(y => y.is_ref === true)?._key)?.oral_exposure?.exposure_day_per_week);
        let human_expo_week = parseFloat(population_concerned.find(o => o.name === "general")?.human_exposure_per_week);
        let animal_r_a = parseFloat(animal_route_administrations.find(o => o.name === cont.find(x => x._key === object.containers.find(y => y.is_ref === true)?._key)?.oral_exposure?.animal_route_administration)?.value);
        let human_route_expo = parseFloat(human_route_exposure.find(o => o.name === "oral")?.value);
        let a_s = parseFloat(animals.find(o => o.name === cont.find(x => x._key === object.containers.find(y => y.is_ref === true)?._key)?.oral_exposure?.animal)?.allometric_scaling);
        let ufa = parseFloat(uncertainty_factors.find(o => o.name === "UFA constant")?.oral_value);
        let ufh = parseFloat(getObjectValueByKey(uncertainty_factors.find(o => o.name === "UFH"), "general"));
        let ufs = parseFloat(getObjectValueByKey(uncertainty_factors.find(o => o.name === "UFS"), cont.find(x => x._key === object.containers.find(y => y.is_ref === true)?._key)?.oral_exposure?.study_duration));
        let ufl = parseFloat(getObjectValueByKey(uncertainty_factors.find(o => o.name === "UFL"), cont.find(x => x._key === object.containers.find(y => y.is_ref === true)?._key)?.oral_exposure?.dose_descriptor === "LOAEL" ? (cont.find(x => x._key === object.containers.find(y => y.is_ref === true)?._key)?.oral_exposure?.dose_descriptor + "_" + cont.find(x => x._key === object.containers.find(y => y.is_ref === true)?._key)?.oral_exposure?.effects_severity) : cont.find(x => x._key === object.containers.find(y => y.is_ref === true)?._key)?.oral_exposure?.dose_descriptor));
        let ufdi = parseFloat(cont.find(x => x._key === object.containers.find(y => y.is_ref === true)?._key)?.oral_exposure?.UFDi);
        let ufdii = parseFloat(cont.find(x => x._key === object.containers.find(y => y.is_ref === true)?._key)?.oral_exposure?.UFDii);
        let human_d_i_r = parseFloat(population_concerned.find(o => o.name === "general")?.human_body_weight);
        let percentage = parseFloat(object.containers.find(y => y.is_ref === true)?.percentage);

        let modified_pod = (pod_value * (expo_h_p_d / human_expo_day) * (expo_d_p_w / human_expo_week) * (animal_r_a / human_route_expo));
        let overall_uf = ((ufa * a_s) * ufh * ufs * ufl * (ufdi * ufdii));
        let h_d_i_r = ((human_d_i_r) * (100 / percentage));

        return calculIndividualCompositionADE(modified_pod, overall_uf, h_d_i_r);
    }
}

/**
 * Calculates the MRL value.
 *
 * @param {string} type - The type of exposure (inhalation or oral).
 * @param {object} object - The object containing toxicity data.
 * @param {array} plateforms - Array of platform objects.
 * @param {string} plateform - The platform name.
 * @param {array} animals - Array of animal objects.
 * @param {array} population_concerned - Array of population concerned objects.
 * @param {string} pop_conc - The population concerned name.
 * @param {array} animal_route_administrations - Array of animal route administration objects.
 * @param {array} human_route_exposure - Array of human route exposure objects.
 * @param {array} uncertainty_factors - Array of uncertainty factor objects.
 * @param {array} cont - Array of container objects.
 * @return {number} - The MRL value.
 */
export function calculateMRL(type, object, plateforms, plateform, animals, population_concerned, pop_conc, animal_route_administrations, human_route_exposure, uncertainty_factors, cont) {
    let ADE = { "inhalation": 0, "oral": 0 };
    let p_product_daily_m_i_u = parseFloat(plateforms.find(o => o.name === plateform)?.product_daily_max_item_use);
    let p_transfer_ratio = parseFloat(plateforms.find(o => o.name === plateform)?.transfer_ratio);
    let p_mass_item = parseFloat(plateforms.find(o => o.name === plateform)?.mass_item);

    if (object.calculate_MRL_by_containers === true) {

        let appli_ttc = calculateCompositionApplicableTTC(type, object, cont);
        let adi = calculateCompositionADI(type, object, cont, population_concerned, pop_conc);

        ADE[type] = calculateCompositionADE(type, object, cont, animals, population_concerned, animal_route_administrations, human_route_exposure, uncertainty_factors);

        if (type === "oral" && !isNaN(adi) && adi !== 0) {
            return ((adi) / (p_product_daily_m_i_u * p_transfer_ratio)) * (1000000 / p_mass_item);
        } else {
            if (!isNaN(ADE[type]) && !isNaN(appli_ttc) && ADE[type] > appli_ttc) { // 2 values !== NaN && ADE > appli_ttc
                return ((ADE[type]) / (p_product_daily_m_i_u * p_transfer_ratio)) * (1000000 / p_mass_item);

            } else if (!isNaN(ADE[type]) && !isNaN(appli_ttc) && ADE[type] < appli_ttc) { // 2 values !== NaN && ADE < appli_ttc 
                return ((appli_ttc) / (p_product_daily_m_i_u * p_transfer_ratio)) * (1000000 / p_mass_item);

            } else if (!isNaN(ADE[type]) && isNaN(appli_ttc)) { // ADE !== NaN AND appli_TTC === NaN
                return ((ADE[type]) / (p_product_daily_m_i_u * p_transfer_ratio)) * (1000000 / p_mass_item);

            } else if (isNaN(ADE[type]) && !isNaN(appli_ttc)) { // ADE === NaN AND appli_TTC !== NaN
                return ((appli_ttc) / (p_product_daily_m_i_u * p_transfer_ratio)) * (1000000 / p_mass_item);

            } else { // ALL other possibilities
                return NaN;
            }
        }

    } else {

        if (type === "inhalation") {
            var appli_ttc = parseFloat(object.tox_data.inhalation_exposure.applicable_TTC);
        } else {
            var appli_ttc = parseFloat(object.tox_data.oral_exposure.applicable_TTC);
        }

        let adi = calculateADI(type, parseFloat(object.tox_data.oral_exposure.ADI), population_concerned, pop_conc);

        ADE[type] = calculateADE(type, object, population_concerned, pop_conc, animal_route_administrations, human_route_exposure, uncertainty_factors, animals);

        if (type === "oral" && !isNaN(adi) && adi !== 0) {
            return ((adi) / (p_product_daily_m_i_u * p_transfer_ratio)) * (1000000 / p_mass_item);
        } else {
            if (!isNaN(ADE[type]) && !isNaN(appli_ttc) && ADE[type] > appli_ttc) { // 2 values !== NaN && ADE > appli_ttc
                return ((ADE[type]) / (p_product_daily_m_i_u * p_transfer_ratio)) * (1000000 / p_mass_item);

            } else if (!isNaN(ADE[type]) && !isNaN(appli_ttc) && ADE[type] < appli_ttc) { // 2 values !== NaN && ADE < appli_ttc 
                return ((appli_ttc) / (p_product_daily_m_i_u * p_transfer_ratio)) * (1000000 / p_mass_item);

            } else if (!isNaN(ADE[type]) && isNaN(appli_ttc)) { // ADE !== NaN AND appli_TTC === NaN
                return ((ADE[type]) / (p_product_daily_m_i_u * p_transfer_ratio)) * (1000000 / p_mass_item);

            } else if (isNaN(ADE[type]) && !isNaN(appli_ttc)) { // ADE === NaN AND appli_TTC !== NaN
                return ((appli_ttc) / (p_product_daily_m_i_u * p_transfer_ratio)) * (1000000 / p_mass_item);

            } else { // ALL other possibilities
                return NaN;
            }
        }
    }
}

/**
 * Calculates the local sensitization MRL value.
 *
 * @param {string} type - The type of exposure (inhalation or oral).
 * @param {object} object - The object containing toxicity data.
 * @param {array} plateforms - Array of platform objects.
 * @param {string} plateform - The platform name.
 * @param {array} cont - Array of container objects.
 * @return {number} - The local sensitization MRL value.
 */
export function calculateLocalSensitizationMRL(type, object, plateforms, plateform, cont) {
    if (object.calculate_MRL_by_containers === false) {
        let ifra_cat = parseFloat(object.tox_data.sensitization_tolerance.IFRA_cat_6);
        let nesil = parseFloat(object.tox_data.sensitization_tolerance.NESIL);
        let p_mass_item = parseFloat(plateforms.find(o => o.name === plateform)?.mass_item);
        let p_cont_surf = parseFloat(plateforms.find(o => o.name === plateform)?.contact_surface);
        let p_ret_rate = parseFloat(plateforms.find(o => o.name === plateform)?.retention_rate);
        let p_transfer_ratio = parseFloat(plateforms.find(o => o.name === plateform)?.transfer_ratio);

        return !isNaN(ifra_cat) && ifra_cat !== 0 && type === "oral" ? (ifra_cat * 10000) : nesil === 0 ? NaN : ((calculateAEL(type, object, plateforms, plateform, cont) * p_cont_surf * 1000000) / (p_mass_item * 1000 * p_ret_rate * p_transfer_ratio));
    } else {
        let ifra_cat = parseFloat(cont.find(x => x._key === object.containers.find(y => y.is_ref === true)?._key)?.IFRA_cat);
        let nesil = parseFloat(cont.find(x => x._key === object.containers.find(y => y.is_ref === true)?._key)?.NESIL);
        let p_mass_item = parseFloat(plateforms.find(o => o.name === plateform)?.mass_item);
        let p_cont_surf = parseFloat(plateforms.find(o => o.name === plateform)?.contact_surface);
        let p_ret_rate = parseFloat(plateforms.find(o => o.name === plateform)?.retention_rate);
        let p_transfer_ratio = parseFloat(plateforms.find(o => o.name === plateform)?.transfer_ratio);
        let percentage = parseFloat(object.containers.find(y => y.is_ref === true)?.percentage);

        let sensi_mrl = !isNaN(ifra_cat) && ifra_cat !== 0 && type === "oral" ? (ifra_cat * 10000 * (100 / percentage)) : nesil === 0 ? NaN : ((calculateAEL(type, object, plateforms, plateform, cont) * p_cont_surf * 1000000) / (p_mass_item * 1000 * p_ret_rate * p_transfer_ratio));

        return sensi_mrl;
    }
}

/**
 * Calculates the final MRL value.
 *
 * @param {string} type - The type of exposure (inhalation or oral).
 * @param {object} object - The object containing toxicity data.
 * @param {array} plateforms - Array of platform objects.
 * @param {string} plateform - The platform name.
 * @param {array} animals - Array of animal objects.
 * @param {array} population_concerned - Array of population concerned objects.
 * @param {string} pop_conc - The population concerned name.
 * @param {array} animal_route_administrations - Array of animal route administration objects.
 * @param {array} human_route_exposure - Array of human route exposure objects.
 * @param {array} uncertainty_factors - Array of uncertainty factor objects.
 * @param {array} cont - Array of container objects.
 * @return {number} - The final MRL value.
 */
export function calculateFinalMRL(type, object, plateforms, plateform, animals, population_concerned, pop_conc, animal_route_administrations, human_route_exposure, uncertainty_factors, cont) {
    let mrl = calculateMRL(type, object, plateforms, plateform, animals, population_concerned, pop_conc, animal_route_administrations, human_route_exposure, uncertainty_factors, cont);
    let sensi = calculateLocalSensitizationMRL(type, object, plateforms, plateform, cont);

    if (!isNaN(mrl) && !isNaN(sensi)) {
        return Math.min(mrl, sensi);
    } else if (!isNaN(mrl) && isNaN(sensi)) {
        return mrl;
    } else if (isNaN(mrl) && !isNaN(sensi)) {
        return sensi;
    } else {
        return NaN;
    }
}

/**
 * Calculates the UFA value.
 *
 * @param {string} type - The type of exposure (inhalation or oral).
 * @param {object} object - The object containing toxicity data.
 * @param {array} uncertainty_factors - Array of uncertainty factor objects.
 * @param {array} animals - Array of animal objects.
 * @return {number} - The UFA value.
 */
export function calculateUFA(type, object, uncertainty_factors, animals) {
    let ufa_constant = type === "oral" ? parseFloat(uncertainty_factors.find(o => o.name === "UFA constant")?.oral_value) : parseFloat(uncertainty_factors.find(o => o.name === "UFA constant")?.inhalation_value);
    let a_s = type === "oral" ? animals.find(o => o.name === object.tox_data.oral_exposure.toxicity.POD.animals)?.allometric_scaling : 1;
    return ufa_constant * a_s;
}

/**
 * Calculates the individual composition UFA value.
 *
 * @param {string} type - The type of exposure (inhalation or oral).
 * @param {object} object - The object containing toxicity data.
 * @param {array} uncertainty_factors - Array of uncertainty factor objects.
 * @param {array} animals - Array of animal objects.
 * @return {number} - The individual composition UFA value.
 */
export function calculateIndividualCompositionUFA(type, object, uncertainty_factors, animals) {
    let ufa_constant = type === "oral" ? parseFloat(uncertainty_factors.find(o => o.name === "UFA constant")?.oral_value) : parseFloat(uncertainty_factors.find(o => o.name === "UFA constant")?.inhalation_value);
    let a_s = type === "oral" ? animals.find(o => o.name === object.oral_exposure.animal)?.allometric_scaling : 1;
    return ufa_constant * a_s;
}

/**
 * Calculates the UFD value.
 *
 * @param {string} type - The type of exposure (inhalation or oral).
 * @param {object} object - The object containing toxicity data.
 * @return {number} - The UFD value.
 */
export function calculateUFD(type, object) {
    let ufdi = type === "oral" ? parseFloat(object.tox_data.oral_exposure.toxicity.uncertainty_factors.UFDi) : parseFloat(object.tox_data.inhalation_exposure.toxicity.uncertainty_factors.UFDi);
    let ufdii = type === "oral" ? parseFloat(object.tox_data.oral_exposure.toxicity.uncertainty_factors.UFDii) : parseFloat(object.tox_data.inhalation_exposure.toxicity.uncertainty_factors.UFDii);
    return ufdi * ufdii;
}

/**
 * Calculates the individual composition UFD value.
 *
 * @param {string} type - The type of exposure (inhalation or oral).
 * @param {object} object - The object containing toxicity data.
 * @return {number} - The individual composition UFD value.
 */
export function calculateIndividualCompositionUFD(type, object) {
    let ufdi = type === "oral" ? parseFloat(object.oral_exposure.UFDi) : parseFloat(object.inhalation_exposure.UFDi);
    let ufdii = type === "oral" ? parseFloat(object.oral_exposure.UFDii) : parseFloat(object.inhalation_exposure.UFDii);
    return ufdi * ufdii;
}

/**
 * Calculates the individual composition overall UF value.
 *
 * @param {string} type - The type of exposure (inhalation or oral).
 * @param {object} object - The object containing toxicity data.
 * @param {array} uncertainty_factors - Array of uncertainty factor objects.
 * @param {string} pop_conc - The population concerned name.
 * @param {array} animals - Array of animal objects.
 * @return {number} - The individual composition overall UF value.
 */
export function calculateIndividualCompositionOverallUF(type, object, uncertainty_factors, pop_conc, animals) {
    if (!isNullUndefinedEmpty(object)) {
        if (type === "inhalation") {
            let ufa = calculateIndividualCompositionUFA(type, object, uncertainty_factors, animals);
            let ufh = parseFloat(getObjectValueByKey(uncertainty_factors.find(o => o.name === "UFH"), "general"));
            let ufs = parseFloat(getObjectValueByKey(uncertainty_factors.find(o => o.name === "UFS"), object.inhalation_exposure.study_duration));
            let ufl = parseFloat(getObjectValueByKey(uncertainty_factors.find(o => o.name === "UFL"), object.inhalation_exposure.dose_descriptor === "LOAEL" ? (object.inhalation_exposure.dose_descriptor + "_" + object.inhalation_exposure.effects_severity) : object.inhalation_exposure.dose_descriptor));
            let ufd = calculateIndividualCompositionUFD(type, object);
            return ufa * ufh * ufs * ufl * ufd;
        } else {
            let ufa = calculateIndividualCompositionUFA(type, object, uncertainty_factors, animals);
            let ufh = parseFloat(getObjectValueByKey(uncertainty_factors.find(o => o.name === "UFH"), "general"));
            let ufs = parseFloat(getObjectValueByKey(uncertainty_factors.find(o => o.name === "UFS"), object.oral_exposure.study_duration));
            let ufl = parseFloat(getObjectValueByKey(uncertainty_factors.find(o => o.name === "UFL"), object.oral_exposure.dose_descriptor === "LOAEL" ? (object.oral_exposure.dose_descriptor + "_" + object.oral_exposure.effects_severity) : object.oral_exposure.dose_descriptor));
            let ufd = calculateIndividualCompositionUFD(type, object);
            return ufa * ufh * ufs * ufl * ufd;
        }
    }
}

/**
 * Calculates the overall UF value.
 *
 * @param {string} type - The type of exposure (inhalation or oral).
 * @param {object} object - The object containing toxicity data.
 * @param {array} uncertainty_factors - Array of uncertainty factor objects.
 * @param {string} pop_conc - The population concerned name.
 * @param {array} animals - Array of animal objects.
 * @return {number} - The overall UF value.
 */
export function calculateOverallUF(type, object, uncertainty_factors, pop_conc, animals) {
    if (type === "inhalation") {
        let ufa = calculateUFA(type, object, uncertainty_factors, animals);
        let ufh = parseFloat(getObjectValueByKey(uncertainty_factors.find(o => o.name === "UFH"), pop_conc));
        let ufs = parseFloat(getObjectValueByKey(uncertainty_factors.find(o => o.name === "UFS"), object.tox_data.inhalation_exposure.toxicity.POD.study_duration));
        let ufl = parseFloat(getObjectValueByKey(uncertainty_factors.find(o => o.name === "UFL"), object.tox_data.inhalation_exposure.toxicity.POD.dose_descriptor === "LOAEL" ? (object.tox_data.inhalation_exposure.toxicity.POD.dose_descriptor + "_" + object.tox_data.inhalation_exposure.toxicity.POD.effects_severity) : object.tox_data.inhalation_exposure.toxicity.POD.dose_descriptor));
        let ufd = calculateUFD(type, object);
        return ufa * ufh * ufs * ufl * ufd;
    } else {
        let ufa = calculateUFA(type, object, uncertainty_factors, animals);
        let ufh = parseFloat(getObjectValueByKey(uncertainty_factors.find(o => o.name === "UFH"), pop_conc));
        let ufs = parseFloat(getObjectValueByKey(uncertainty_factors.find(o => o.name === "UFS"), object.tox_data.oral_exposure.toxicity.POD.study_duration));
        let ufl = parseFloat(getObjectValueByKey(uncertainty_factors.find(o => o.name === "UFL"), object.tox_data.oral_exposure.toxicity.POD.dose_descriptor === "LOAEL" ? (object.tox_data.oral_exposure.toxicity.POD.dose_descriptor + "_" + object.tox_data.oral_exposure.toxicity.POD.effects_severity) : object.tox_data.oral_exposure.toxicity.POD.dose_descriptor));
        let ufd = calculateUFD(type, object);
        return ufa * ufh * ufs * ufl * ufd;
    }
}

/**
 * Calculates the AEL value.
 *
 * @param {string} type - The type of exposure (inhalation or oral).
 * @param {object} object - The object containing toxicity data.
 * @param {array} plateforms - Array of platform objects.
 * @param {string} plateform - The platform name.
 * @param {array} cont - Array of container objects.
 * @return {number} - The AEL value.
 */
export function calculateAEL(type, object, plateforms, plateform, cont) {
    let percentage = parseFloat(object.containers.find(y => y.is_ref === true)?.percentage);
    let p_saf = parseFloat(plateforms.find(o => o.name === plateform)?.SAF);
    let cel = parseFloat(plateforms.find(o => o.name === plateform)?.safety_factor);

    if (object.calculate_MRL_by_containers === false) {
        let nesil = parseFloat(object.tox_data.sensitization_tolerance.NESIL);
        return nesil / (p_saf);
    } else {
        let nesil = parseFloat(cont.find(x => x._key === object.containers.find(y => y.is_ref === true)?._key)?.NESIL);
        return (nesil / (p_saf)) * (100 / percentage);
    }
}

/**
 * Calculates the ADI value.
 *
 * @param {string} type - The type of exposure (inhalation or oral).
 * @param {number} adi - The ADI value.
 * @param {array} population_concerned - Array of population concerned objects.
 * @param {string} pop_conc - The population concerned name.
 * @return {number} - The ADI value.
 */
export function calculateADI(type, adi, population_concerned, pop_conc) {
    let human_b_w = parseFloat(population_concerned.find(o => o.name === pop_conc)?.human_body_weight);
    return adi * human_b_w;
}

/**
 * Calculates the composition ADI value.
 *
 * @param {string} type - The type of exposure (inhalation or oral).
 * @param {object} object - The object containing toxicity data.
 * @param {array} cont - Array of container objects.
 * @param {array} population_concerned - Array of population concerned objects.
 * @param {string} pop_conc - The population concerned name.
 * @return {number} - The composition ADI value.
 */
export function calculateCompositionADI(type, object, cont, population_concerned, pop_conc) {
    let percentage = parseFloat(object.containers.find(y => y.is_ref === true)?.percentage);
    let human_b_w = parseFloat(population_concerned.find(o => o.name === pop_conc)?.human_body_weight);
    let adi = parseFloat(cont.find(x => x._key === object.containers.find(y => y.is_ref === true)?._key)?.oral_exposure?.ADI);
    return adi * human_b_w * (100 / percentage);
}

/**
 * Calculates the composition applicable TTC value.
 *
 * @param {string} type - The type of exposure (inhalation or oral).
 * @param {object} object - The object containing toxicity data.
 * @param {array} cont - Array of container objects.
 * @return {number} - The composition applicable TTC value.
 */
export function calculateCompositionApplicableTTC(type, object, cont) {
    let percentage = parseFloat(object.containers.find(y => y.is_ref === true)?.percentage);
    if (type === "inhalation") {
        let appli_ttc = parseFloat(cont.find(x => x._key === object.containers.find(y => y.is_ref === true)?._key)?.inhalation_exposure?.applicable_TTC);
        return appli_ttc * (100 / percentage);
    } else {
        let appli_ttc = parseFloat(cont.find(x => x._key === object.containers.find(y => y.is_ref === true)?._key)?.oral_exposure?.applicable_TTC);
        return appli_ttc * (100 / percentage);
    }
}

