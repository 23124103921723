import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, Tooltip, Typography } from '@mui/material';
// import DownloadIcon from '@mui/icons-material/Download';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { saveAs } from 'file-saver';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import Docxtemplater from 'docxtemplater';
import { toast } from 'react-toastify';
import { booleanCompoundLogic, getObjectValueByKey, isNCS, isNullUndefinedEmpty, parseSmallDate } from '../utils/utils';
import { calculateADE, calculateAEL, calculateFinalMRL, calculateLocalSensitizationMRL, calculateModifiedPoD, calculateMRL, calculateOverallUF, calculateUFA } from '../utils/calculation';
import { API } from '../services/api/api';


const ReportButton = ({ values,
  population_concerned,
  platform,
  animal,
  animal_route_administration,
  study_test_method,
  study_duration,
  human_route_exposure,
  uncertainty_factors,
  severity,
  containers }) => {

  const [open, setOpen] = React.useState(false);
  const [type_key, setType] = React.useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNoSelection = () => {
    toast.warn("Please select a report to generate !");
  }

  const handlePlatformMultiple = () => {
    return platform.platform?.oral === true && platform.platform?.inhalation === true;
  };

  const handleAssessmentType = (event) => {
    setType(event.target.value);
  };

  const isEmptyObject = (object) => {
    return Object.keys(object).length === 0;
  };

  const setExposureTypeAndReport = (oral, inhalation, isNCS, calculateMRL) => {
    const type_report = { type_key: "", report_name: "" };
    const basePath = isNCS ? (calculateMRL ? "/NCS_compo_based_Tox_Risk_Assessment_Summary_" : "/NCS_based_Tox_Risk_Assessment_Summary_") : "/Individual_Tox_Risk_Assessment_Summary_";

    if (oral && !inhalation) {
      type_report.type_key = "oral_exposure";
      type_report.report_name = basePath + "Oral.docx";
    } else if (!oral && inhalation) {
      type_report.type_key = "inhalation_exposure";
      type_report.report_name = basePath + "Inhalation.docx";
    } else if (oral && inhalation) {
      type_report.type_key = type_key;
      type_report.report_name = basePath + (type_key === "inhalation_exposure" ? "Inhalation.docx" : "Oral.docx");
    }

    return type_report;
  };

  const loadFile = (url) => {
    return new Promise((resolve, reject) => {
      PizZipUtils.getBinaryContent(url, (error, content) => {
        if (error) {
          reject(error);
        } else {
          resolve(content);
        }
      });
    });
  };

  const handleDownloadReport = async () => {

    try {
      if (!isNullUndefinedEmpty(platform.platform)
        && !isNullUndefinedEmpty(population_concerned.pop_conc)
        && !isEmptyObject(platform.platform)
        && !isEmptyObject(population_concerned.pop_conc)) {

        const type_report = setExposureTypeAndReport(platform.platform.oral, platform.platform.inhalation, isNCS(values.categories), values.calculate_MRL_by_containers);

        const content = await loadFile(type_report.report_name);
        if (!content) {
          toast.warn("Content not fully loaded, please retry!");
          return;
        }

        const zip = new PizZip(content);
        const doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
        });

        const type = type_report.type_key.split("_")[0];
        const limiting_component = values.calculate_MRL_by_containers ? (await API.getPropertyData("_key", values.containers.find(o => o.is_ref === true)?._key))[0] ?? null : null;


        doc.render({
          // Generic
          ...booleanCompoundLogic(type, type_report.type_key, values, limiting_component, platform, population_concerned, animal, animal_route_administration, human_route_exposure, uncertainty_factors, containers),
          CHEM_NAME: values.name,
          CAS_NUMBER: values.cas,
          BOT_NAME: values.chem_id.botanical_name,
          PLANT_FAMILY: values.chem_id.plant_family_name,
          BOT_CAT: values.chem_id.botanical_category,
          NCS_PROCESS: values.chem_id.ncs_processing,
          COMPO_REF: values.composition_source,
          RESPIRATORY_TRACT_IRRITATION: values.tox_data.local_effect.respiratory_tract_irritation,
          SKIN_IRRITATION: values.tox_data.local_effect.skin_irritation,
          A_D_M_E: values.tox_data.ADME,
          SKIN_SENSITIZATION: values.tox_data.sensitization_tolerance.skin_sensitization,
          SKIN_SENS_ALERT: values.tox_data.sensitization_tolerance.skin_sensitization_alert,
          EYE_IRRITATION: values.tox_data.local_effect.eye_irritation,
          OTHER_LOCAL_EFFECTS: values.tox_data.local_effect.other_local_effect,
          RESPIRATORY_SENSITIZATION: values.tox_data.sensitization_tolerance.respiratory_sensitization,
          RESPIRATORY_SENS_ALERT: values.tox_data.sensitization_tolerance.respiratory_sensitization_alert,
          ORAL_ALLERGY_INTOLERANCE: values.tox_data.sensitization_tolerance.oral_allergy_intolerance,
          IFRA_STATUS: values.tox_data.sensitization_tolerance.IFRA_status,
          IFRA_RECOMMENDATION_COMMENTS: values.tox_data.sensitization_tolerance.IFRA_recommendation_comment,
          IFRA_CAT_1: values.tox_data.sensitization_tolerance.IFRA_cat_1,
          IFRA_CAT_6: values.tox_data.sensitization_tolerance.IFRA_cat_6,
          CMR_ALERTS: values.tox_data.general_toxicity.CMR_alerts,
          ACUTE_TOXICITY: values.tox_data.general_toxicity.acute_toxicity,
          R_D_T_INHALATION: values.tox_data.general_toxicity.repeated_dose_toxicity.inhalation,
          R_D_T_ORAL: values.tox_data.general_toxicity.repeated_dose_toxicity.oral,
          R_D_T_DERMAL: values.tox_data.general_toxicity.repeated_dose_toxicity.dermal,
          G_ENOTOXICITY: values.tox_data.general_toxicity.genotoxicity,
          C_ARCINOGENICITY: values.tox_data.general_toxicity.carcinogenicity,
          REPRODUCTIVE_DEVELOPMENTAL_TOXICITY: values.tox_data.general_toxicity.reproductive_developmental_toxicity,
          CARDIOPULMONARY_EFFECTS: values.tox_data.general_toxicity.cardiopulmonary_effects,
          IN_SILICO_DATA: values.tox_data.in_silico_data,
          OTHER_RELEVANT_TOXICITY: values.tox_data.general_toxicity.other_revelant_toxicity,
          TOX_PROFILE_COMMENT: values.tox_data.comment,
          A_DDICTIVENESS: values.tox_data.addictivness_attractiveness,
          CLP_HARMONIZED_CLASSIFICATION: values.chem_id.CLP_health_harmo_class,
          IARC_CLASSIFICATION: values.chem_id.IARC_class,
          NTP_CLASSIFICATION: values.chem_id.NTP_class,
          PROP_65: values.chem_id.prop_65,
          MOLECULAR_WEIGHT: values.chem_id.mol_weight,
          BOILING_POINT: values.chem_id.boiling_point,
          CRAMERCLASS_REFERENCE: values.chem_id.CRAMER_class_ref,
          CRAMER_CLASS: values.chem_id.CRAMER_class,
          NESIL_VALUE: values.tox_data.sensitization_tolerance.NESIL,
          NESIL_COMMENTS: values.tox_data.sensitization_tolerance.NESIL_comment,

          // Population concerned
          HUM_INH_RATE: population_concerned.pop_conc?.human_daily_inhalation_rate,
          POPULATION_BODYWEIGHT: population_concerned.pop_conc?.human_body_weight,
          POPULATION_CONCERNED: population_concerned.pop_conc?.label,
          POPULATION_EXPOSURE_DAY: population_concerned.pop_conc?.human_exposure_per_day,
          POPULATION_EXPOSURE_WK: population_concerned.pop_conc?.human_exposure_per_week,

          // Platform
          PLATFORM: platform.platform?.name,
          DAILY_MAX_ITEM_USE: platform.platform?.product_daily_max_item_use,
          PRODUCT_DAILY_MAX_USE: platform.platform?.product_daily_max_unit_use,
          TRANSFER_RATIO: platform.platform?.transfer_ratio,
          CONT_SURFACE: platform.platform?.contact_surface,
          RETENTION_RATE: platform.platform?.retention_rate,
          MASS_ITEM: platform.platform?.mass_item,
          S_A_F: platform.platform?.SAF,

          // NCS Specific 
          CONTAINERS: values.containers,

          // Method dependent 
          POD_DOSE_DESCRIPTOR: values.tox_data[type_report.type_key]?.toxicity.POD.dose_descriptor,
          POD_VALUE: values.tox_data[type_report.type_key]?.toxicity.POD.value,
          POD_UNIT: values.tox_data[type_report.type_key]?.toxicity.POD.unit,
          GLP_COMPLIANCE: values.tox_data[type_report.type_key]?.toxicity.POD.GLP_compliance,
          STUDY_REFERENCE: values.tox_data[type_report.type_key]?.toxicity.POD.study_ref,
          MATERIAL_NAME: values.tox_data[type_report.type_key]?.toxicity.POD.read_across.material_name,
          MATERIAL_CAS_NO: values.tox_data[type_report.type_key]?.toxicity.POD.read_across.material_cas,
          READ_ACROSS_MATERIAL_NAME: values.tox_data[type_report.type_key]?.toxicity.POD.read_across.material_name,
          READ_ACROSS_MATERIAL_CAS_NO: values.tox_data[type_report.type_key]?.toxicity.POD.read_across.material_cas,
          READ_ACROSS_MOL_WEIGHT: values.tox_data[type_report.type_key]?.toxicity.POD.read_across.material_mw,
          TYPE_OF_EFFECT: values.tox_data[type_report.type_key]?.toxicity.POD.type_effect,
          EFFECTS_DESCRIPTION: values.tox_data[type_report.type_key]?.toxicity.POD.effects_description,
          EFFECTS_SEVERITY: severity.find(x => x.name === values.tox_data[type_report.type_key]?.toxicity.POD.effects_severity)?.label,
          EXPOSURE_ANIMAL_HOURS_DAY: values.tox_data[type_report.type_key]?.toxicity.POD.exposure_hours_per_day,
          EXPOSURE_ANIMAL_DAYS_WK: values.tox_data[type_report.type_key]?.toxicity.POD.exposure_day_per_week,
          UFDI: values.tox_data[type_report.type_key]?.toxicity.uncertainty_factors.UFDi,
          UFD_I_COMMENTS: values.tox_data[type_report.type_key]?.toxicity.uncertainty_factors.UFDi_comments,
          UFD_II: values.tox_data[type_report.type_key]?.toxicity.uncertainty_factors.UFDii,
          COMMENTS_UFD_I_I: values.tox_data[type_report.type_key]?.toxicity.uncertainty_factors.UFDii_comments,
          APPLICABLE_TTC: values.tox_data[type_report.type_key]?.applicable_TTC,

          STUDY_TEST_METHOD: study_test_method[type_report.type_key]?.label,
          ANIMAL: animal[type_report.type_key]?.label,
          ALLOMETRIC_SCALING: animal[type_report.type_key]?.allometric_scaling,
          STANDARD_RESPIRATORY_VOLUME: animal[type_report.type_key]?.standard_respiratory_volume,
          ANI_ROUTE_ADMINISTRATION: animal_route_administration[type_report.type_key]?.label,
          ABS_POD: animal_route_administration[type_report.type_key]?.value,
          ABS_HUMAN: human_route_exposure[type_report.type_key]?.value,
          STUDY_DURATION: study_duration[type_report.type_key]?.label,

          // Method specific
          ADI_VALUE: values.tox_data.oral_exposure.ADI,
          ADI_REF: values.tox_data.oral_exposure.ADI_ref,

          // NCS Composition based
          LIMITING_COMPONENT_NAME: limiting_component?.name,
          LIMITING_COMPONENT_CAS: limiting_component?.cas,
          LIMITING_COMPONENT_PERCENTAGE: values.containers.find(o => o?.is_ref === true)?.percentage,
          LIMITING_COMPONENT_CRAMERCLAS_S: limiting_component?.chem_id.CRAMER_class,
          LIMITING_COMPONENT_CRAMERCLASS_REFERENCE: limiting_component?.chem_id.CRAMER_class_ref,
          LIMITING_COMPONENT_APPLICABLETTC: limiting_component?.tox_data[type_report.type_key].applicable_TTC,
          NCS_LIMITING_COMPONENT_TTC: (limiting_component?.tox_data[type_report.type_key].applicable_TTC * (100 / values.containers.find(o => o?.is_ref === true)?.percentage)).toFixed(2),
          LIMITING_COMPONENT_POD_DOSEDESCRIPTOR: limiting_component?.tox_data[type_report.type_key].toxicity.POD.dose_descriptor,
          LIMITING_COMPONENT_PODVALUE: limiting_component?.tox_data[type_report.type_key].toxicity.POD.value,
          LIMITING_COMPONENT_PODUNIT: limiting_component?.tox_data[type_report.type_key].toxicity.POD.unit,
          LIMITING_COMPONENT_STUDY_TESTMETHOD: study_duration.study_durations.find(o => o.name === limiting_component?.tox_data[type_report.type_key].toxicity.POD.study_duration)?.label,
          LIMITING_COMPONENT_GLPCOMPLIANCE: limiting_component?.tox_data[type_report.type_key].toxicity.POD.GLP_compliance,
          LIMITING_COMPONENTA_NIMAL: animal.animals.find(o => o.name === limiting_component?.tox_data[type_report.type_key].toxicity.POD.animals)?.label,
          LIMITING_COMPONENT_ANI_ROUTEADMINISTRATION: animal_route_administration.animal_route_administrations.find(o => o.name === limiting_component?.tox_data[type_report.type_key].toxicity.POD.animal_route_administration)?.label,
          LIMITING_COMPONENT_STUDYDURATION: study_duration.study_durations.find(o => o.name === limiting_component?.tox_data[type_report.type_key].toxicity.POD.study_duration)?.label,
          LIMITING_COMPONENT_STUDYREFERENCE: limiting_component?.tox_data[type_report.type_key].toxicity.POD.study_ref,
          LIMITING_COMPONENT_MATERIALNAME: limiting_component?.tox_data[type_report.type_key].toxicity.POD.read_across.material_name,
          LIMITING_COMPONENT_MATERIAL_CASNO: limiting_component?.tox_data[type_report.type_key].toxicity.POD.read_across.material_cas,
          LIMITING_COMPONENT_TYPE_OFEFFECT: limiting_component?.tox_data[type_report.type_key].toxicity.POD.type_effect,
          LIMITING_COMPONENT_EFFECTSDESCRIPTION: limiting_component?.tox_data[type_report.type_key].toxicity.POD.effects_description,
          LIMITING_COMPONENT_EFFECTSSEVERITY: severity.find(x => x.name === limiting_component?.tox_data[type_report.type_key].toxicity.POD.effects_severity)?.label,
          LIMITING_COMPONENT_MODIFIEDPOD: limiting_component ? calculateModifiedPoD(type,
            limiting_component,
            population_concerned.population_concerned,
            population_concerned.pop_conc.name,
            animal.animals,
            animal_route_administration.animal_route_administrations,
            human_route_exposure.human_route_exposures).toFixed(2) : null,
          LIMITING_COMPONENT_STANDARDRESPIVOLUME: animal.animals.find(o => o.name === limiting_component?.tox_data[type_report.type_key].toxicity.POD.animals)?.standard_respiratory_volume,
          LIMITING_COMPONENT_EXPOSURE_ANIMAL_HOURSDAY: limiting_component?.tox_data[type_report.type_key].toxicity.POD.exposure_hours_per_day,
          LIMITING_COMPONENT_EXPOSURE_ANIMAL_DAYSWK: limiting_component?.tox_data[type_report.type_key].toxicity.POD.exposure_day_per_week,
          LIMITING_COMPONENT_ABSPOD: animal_route_administration.animal_route_administrations.find(o => o.name === limiting_component?.tox_data[type_report.type_key].toxicity.POD.animal_route_administration)?.value,
          LIMITING_COMPONENT_ADEVALUE: limiting_component ? calculateADE(type,
            limiting_component,
            population_concerned.population_concerned,
            population_concerned.pop_conc.name,
            animal_route_administration.animal_route_administrations,
            human_route_exposure.human_route_exposures,
            uncertainty_factors, 
            animal.animals).toFixed(2) : null,
          LIMITING_COMPONENT_U_FS: getObjectValueByKey(uncertainty_factors.find(o => o.name === "UFS"), limiting_component?.tox_data[type_report.type_key].toxicity.POD.study_duration),
          LIMITING_COMPONENT_U_FL: getObjectValueByKey(uncertainty_factors.find(o => o.name === "UFL"),
            limiting_component?.tox_data.inhalation_exposure.toxicity.POD.dose_descriptor === "LOAEL" ? (limiting_component?.tox_data.inhalation_exposure.toxicity.POD.dose_descriptor + "_" + limiting_component?.tox_data.inhalation_exposure.toxicity.POD.effects_severity) : limiting_component?.tox_data.inhalation_exposure.toxicity.POD.dose_descriptor
          ),
          LIMITING_COMPONENT_U_FDI: limiting_component?.tox_data[type_report.type_key].toxicity.uncertainty_factors.UFDi,
          LIMITING_COMPONENT_U_FD_II: limiting_component?.tox_data[type_report.type_key].toxicity.uncertainty_factors.UFDii,
          LIMITING_COMPONENT_U_F_D_ICOMMENTS: limiting_component?.tox_data[type_report.type_key].toxicity.uncertainty_factors.UFDi_comments,
          LIMITING_COMPONENT_COMMENTSU_FD_I_I: limiting_component?.tox_data[type_report.type_key].toxicity.uncertainty_factors.UFDii_comments,
          LIMITING_COMPONENT_OVER_ALL_UF: limiting_component ? calculateOverallUF(type,
            limiting_component,
            uncertainty_factors,
            population_concerned.pop_conc.name,
            animal.animals
          ) : null,
          LIMITING_COMPONENTM_R_L: calculateMRL(type,
            values,
            platform.platforms,
            platform.platform.name,
            animal.animals,
            population_concerned.population_concerned,
            population_concerned.pop_conc.name,
            animal_route_administration.animal_route_administrations,
            human_route_exposure.human_route_exposures,
            uncertainty_factors,
            containers).toFixed(2),
          LIMITING_COMPONENT_SKIN_SENSALERT: limiting_component?.tox_data.sensitization_tolerance.skin_sensitization_alert,
          LIMITING_COMPONENT_IFRA_CAT6: limiting_component?.tox_data.sensitization_tolerance.IFRA_cat_6,
          LIMITING_COMPONENT_NESILVALUE: limiting_component?.tox_data.sensitization_tolerance.NESIL,
          LIMITING_COMPONENT_NESILCOMMENTS: limiting_component?.tox_data.sensitization_tolerance.NESIL_comment,
          NCS_LIMITING_COMPONENT_AEL: calculateAEL(type,
            values,
            platform.platforms,
            platform.platform.name,
            containers).toFixed(2),
          LIMITING_COMPONENT_MRL_LOCALSENSI: calculateLocalSensitizationMRL(type,
            values,
            platform.platforms,
            platform.platform.name,
            containers).toFixed(2),
          LIMITING_COMPONENT_FINALMRL: calculateFinalMRL(type,
            values,
            platform.platforms,
            platform.platform.name,
            animal.animals,
            population_concerned.population_concerned,
            population_concerned.pop_conc.name,
            animal_route_administration.animal_route_administrations,
            human_route_exposure.human_route_exposures,
            uncertainty_factors,
            containers).toFixed(2),

          // Calculated values
          UFA: calculateUFA(type, values, uncertainty_factors, animal.animals),
          UFH: getObjectValueByKey(uncertainty_factors.find(o => o.name === "UFH"), population_concerned.pop_conc.name),
          UFS: getObjectValueByKey(uncertainty_factors.find(o => o.name === "UFS"), values.tox_data[type_report.type_key]?.toxicity.POD.study_duration),
          UFL: getObjectValueByKey(uncertainty_factors.find(o => o.name === "UFL"),
            values.tox_data[type_report.type_key]?.toxicity.POD.dose_descriptor === "LOAEL" ? (values.tox_data[type_report.type_key]?.toxicity.POD.dose_descriptor + "_" + values.tox_data[type_report.type_key]?.toxicity.POD.effects_severity) : values.tox_data[type_report.type_key]?.toxicity.POD.dose_descriptor
          ),

          OVERALL_UF: calculateOverallUF(type,
            values,
            uncertainty_factors,
            population_concerned.pop_conc.name,
            animal.animals),

          MODIFIED_POD: calculateModifiedPoD(type,
            values,
            population_concerned.population_concerned,
            population_concerned.pop_conc.name,
            animal.animals,
            animal_route_administration.animal_route_administrations,
            human_route_exposure.human_route_exposures).toFixed(2),

          ADE_VALUE: calculateADE(type,
            values,
            population_concerned.population_concerned,
            population_concerned.pop_conc.name,
            animal_route_administration.animal_route_administrations,
            human_route_exposure.human_route_exposures,
            uncertainty_factors,
            animal.animals).toFixed(2),

          AEL_VALUE: calculateAEL(type,
            values,
            platform.platforms,
            platform.platform.name,
            containers).toFixed(2),

          M_RL: calculateMRL(type,
            values,
            platform.platforms,
            platform.platform.name,
            animal.animals,
            population_concerned.population_concerned,
            population_concerned.pop_conc.name,
            animal_route_administration.animal_route_administrations,
            human_route_exposure.human_route_exposures,
            uncertainty_factors,
            containers).toFixed(2),

          MRL_LOCAL_SENSI: calculateLocalSensitizationMRL(type,
            values,
            platform.platforms,
            platform.platform.name,
            containers).toFixed(2),

          FINAL_MRL: calculateFinalMRL(type,
            values,
            platform.platforms,
            platform.platform.name,
            animal.animals,
            population_concerned.population_concerned,
            population_concerned.pop_conc.name,
            animal_route_administration.animal_route_administrations,
            human_route_exposure.human_route_exposures,
            uncertainty_factors,
            containers).toFixed(2),
        });

        const out = doc.getZip().generate({
          type: 'blob',
          mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        });

        const basename = type_report.type_key === "oral_exposure" ? "Toxicological_Risk_Assessment_Summary_Oral_" : "Toxicological_Risk_Assessment_Summary_Inhalation_";
        const filename = "Report_" + basename + values.cas + "_" + parseSmallDate(Date.now()) + ".docx";
        saveAs(out, filename);
      } else {
        toast.warn("Please choose a population concerned and a platform!");
      }
    } catch (ex) {
      console.log(ex);
      toast.error("Error during report generation ! Please contact an administrator.");
    } finally {
      setOpen(false);
    }
  };


  return (
    <>
      {
        !isNullUndefinedEmpty(values) && !isEmptyObject(values) ? (
          <>
            <Tooltip title="Download product-specific TRA report" placement="top" arrow>
              <IconButton aria-label="download" onClick={() => { handlePlatformMultiple() ? handleOpen() : handleDownloadReport() }}>
                <FileOpenIcon />
              </IconButton>
            </Tooltip>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <Typography variant="h6" component="div" sx={{ whiteSpace: 'pre-line' }}>
                  {"For this product, toxicological risk assessment is likely needed for both inhalation and oral exposures.\n\nPlease, consider selecting the reports accordingly."}
                </Typography>
              </DialogTitle>
              <DialogContent>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="assessment_type">Assessment Type</InputLabel>
                  <Select
                    labelId="assessment_type"
                    id="assessment_type"
                    name="assessment_type"
                    value={type_key}
                    onChange={handleAssessmentType}
                  >
                    <MenuItem value={"inhalation_exposure"}>Toxicological Rish Assessment Summary Inhalation</MenuItem>
                    <MenuItem value={"oral_exposure"}>Toxicological Rish Assessment Summary Oral</MenuItem>
                  </Select>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={() => { !isNullUndefinedEmpty(type_key) ? handleDownloadReport() : handleNoSelection() }} color="secondary" autoFocus>
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </>
        ) : null
      }
    </>
  );
};

export default ReportButton;