import fetch from "cross-fetch";
import axios from "axios";
import { CognitoAuth } from './../../auth/cognito';
import { toast } from 'react-toastify';
import { isNullUndefinedEmpty } from "../../utils/utils";
import { faSmileWink } from "@fortawesome/free-solid-svg-icons";

const apiUrl = process.env.REACT_APP_SILVER_API_BASE_URL;
const pubchemImageUrl = process.env.REACT_APP_PUBCHEM_BASE_URL;
const pubchemDataUrl = process.env.REACT_APP_PUBCHEM_DATA_URL;

export const API = {

    // EXTERNAL   
    getPubchemImage: async function(value, cancel = false){
        try{
            // const response = await fetch(
            //     pubchemImageUrl + value + "/cids/JSON"         
            // )
            // .catch((error) => {
            //     console.log(error);
            // });
            // return response.json();
            const url = pubchemImageUrl + value + "/cids/JSON";
            const headers = { 'Content-Type': 'application/json' }

            return await this.get(url, headers, false);

        }catch(ex){
            // throw new Error("Failed to get status data from PubChem for : {"+value+"}");
            toast.warn("Failed to get image from PubChem for : {"+value+"}");
        }
    },

    getPubchemIdentifications: async function(value, cancel = false){
        try{
            const url = apiUrl + "pubchem/data/" + value;
            const headers = { 'Content-Type': 'application/json' }
            
            return await this.get(url, headers);
        }catch(ex){
            console.log(ex);
            // throw new Error("Failed to get status data from PubChem for : {"+value+"}");
            toast.warn("Failed to get data from PubChem for : {"+value+"}");
        }
    },

    getConcentrationByProduct: async function(cas){
       
        try{
            const url = apiUrl + "pct/getConcentrationByProduct/" + cas;
            const headers = { 'Content-Type': 'application/json' }

            return await this.get(url, headers);
        }catch(ex){
            //throw new Error("Failed to get filtered data for : {"+value+"}");
            toast.error("Failed to get pct data");
        }
        
    },

    getRodgmanAndPerfetti: async function(cas){
       
        try{
            const url = apiUrl + "ucsd/getRodgmanAndPerfetti/" + cas;
            const headers = { 'Content-Type': 'application/json' }

            return await this.get(url, headers);
        }catch(ex){
            //throw new Error("Failed to get filtered data for : {"+value+"}");
            toast.error("Failed to get ucsd data");
        }
        
    },

    getAcdLabsBP: async function(smiles){
       
        try{
            const url = apiUrl + "acd/getBoilingPoint/" + smiles;
            const headers = { 'Content-Type': 'application/json' }

            return await this.get(url, headers);
        }catch(ex){
            //throw new Error("Failed to get filtered data for : {"+value+"}");
            toast.error("Failed to get ucsd data");
        }
        
    },

    // ---------------------------------------------------------------------------------------------------------

    // INTERNAL
    getFilteredData: async function (value, cancel = false){
        try{
            const url = apiUrl + "compounds/filter/name/" + value;
            const headers = { 'Content-Type': 'application/json' }

            return await this.get(url, headers);
        }catch(ex){
            //throw new Error("Failed to get filtered data for : {"+value+"}");
            toast.error("Failed to get filtered data for : {"+value+"}");
        }
    },

    getPropertyData: async function (property, value, cancel = false){
        try{
            const url = apiUrl + "compounds/"+ property + "/" + value;
            const headers = { 'Content-Type': 'application/json' }

            return await this.get(url, headers);
        }catch(ex){
            //throw new Error("Failed to get filtered data for : {"+value+"}");
            toast.error("Failed to get propertry data for : {"+property+":"+value+"}");
        }
    },

    getAllCompoundsProperty: async function (prop, cancel = false){
        try{
            const url = apiUrl + "compounds/all";
            const values = { property: prop };
            const headers = { 'Content-Type': 'application/json' }

            return await this.post(url, headers, values, null, false);
        }catch(ex){
            //throw new Error("Failed to get filtered data for : {"+value+"}");
            toast.error("Failed to get all CAS Number list");
        }
    },

    getAuditedData: async function (param, cancel = false){
        try{          
            const url = apiUrl + "audits/" + Object.keys(param)[0];
            const headers = { 'Content-Type': 'application/json' }

            return await this.post(url, headers, param, null, false);
        }catch(ex){
            // throw new Error("Failed to get audited data for : {"+author+"}");
            toast.error("Failed to get audited data for : {"+Object.keys(param)[0]+"}");
        }
    },

    getFlaggedComponent: async function (user, component_key, cancel = false){
        try{
            const url = apiUrl + "flagged/" + user + "/" + component_key ;
            const headers = { 'Content-Type': 'application/json' }

            return await this.get(url, headers);
        }catch(ex){
            // throw new Error("Failed to get audited data for : {"+author+"}");
            toast.error("Failed to get flagged data for : {"+user+"}");
        }
    },

    getFlaggedComponents: async function (user, cancel = false){
        try{
            const url = apiUrl + "flagged/" + user;
            const headers = { 'Content-Type': 'application/json' }

            return await this.get(url, headers);
        }catch(ex){
            // throw new Error("Failed to get audited data for : {"+author+"}");
            toast.error("Failed to get flagged data for : {"+user+"}");
        }
    },

    getCategories: async function (cancel = false){
        try{
            const url = apiUrl + "categories";
            const headers = { 'Content-Type': 'application/json' }

            return await this.get(url, headers);
        }catch(ex){
            // throw new Error("Failed to get categories data");
            toast.error("Failed to get categories data");
        }
    },

    getCompoundsSelect: async function (ncs, cancel  = false){
        try{
            const url = apiUrl + "select/options/" + ncs;
            const headers = { 'Content-Type': 'application/json' }

            return await this.get(url, headers);
        }catch(ex){
            // throw new Error("Failed to get compound data for ncs={"+ncs+"}");
            toast.error("Failed to get compound data for ncs={"+ncs+"}");
        }
    },

    getStatus: async function (type, cancel = false){
        try{
            const url = apiUrl + "status/" + type;
            const headers = { 'Content-Type': 'application/json' }

            return await this.get(url, headers);
        }catch(ex){
            // throw new Error("Failed to get status data for type={"+type+"}");
            toast.error("Failed to get status data for type={"+type+"}");
        }
    },

    getPopulationConcerned: async function(cancel = false){
        try{
            const url = apiUrl + "population_concerned/all";
            const headers = { 'Content-Type': 'application/json' }

            return await this.get(url, headers);
        }catch(ex){
            // throw new Error("Failed to get master-data exposure");
            toast.error("Failed to get master-data exposure");
        }
    },

    getAnimals: async function(cancel = false){
        try{
            const url = apiUrl + "animals/all";
            const headers = { 'Content-Type': 'application/json' }

            return await this.get(url, headers);
        }catch(ex){
            // throw new Error("Failed to get master-data exposure");
            toast.error("Failed to get master-data exposure");
        }
    },

    getPlateforms: async function(cancel = false){
        try{
            const url = apiUrl + "plateforms/all";
            const headers = { 'Content-Type': 'application/json' }

            return await this.get(url, headers);
        }catch(ex){
            // throw new Error("Failed to get plateforms datas");
            toast.error("Failed to get plateforms datas");
        }
    },

    getUncertaintyFactors: async function(cancel = false){
        try{
            const url = apiUrl + "uncertainty_factors/all";
            const headers = { 'Content-Type': 'application/json' }

            return await this.get(url, headers);
        }catch(ex){
            // throw new Error("Failed to get plateforms datas");
            toast.error("Failed to get plateforms datas");
        }
    },

    getUFDIComments: async function(cancel = false){
        try{
            const url = apiUrl + "ufdi_comments/all";
            const headers = { 'Content-Type': 'application/json' }

            return await this.get(url, headers);
        }catch(ex){
            // throw new Error("Failed to get plateforms datas");
            toast.error("Failed to get ufdi datas");
        }
    },

    getUFDIIComments: async function(cancel = false){
        try{
            const url = apiUrl + "ufdii_comments/all";
            const headers = { 'Content-Type': 'application/json' }

            return await this.get(url, headers);
        }catch(ex){
            // throw new Error("Failed to get plateforms datas");
            toast.error("Failed to get ufdii datas");
        }
    },

    getBotanicalCategories: async function(cancel = false){
        try{
            const url = apiUrl + "botanical_category/all";
            const headers = { 'Content-Type': 'application/json' }

            return await this.get(url, headers);
        }catch(ex){
            // throw new Error("Failed to get plateforms datas");
            toast.error("Failed to get ufdii datas");
        }
    },

    getNCSProcessing: async function(cancel = false){
        try{
            const url = apiUrl + "ncs_processing/all";
            const headers = { 'Content-Type': 'application/json' }

            return await this.get(url, headers);
        }catch(ex){
            // throw new Error("Failed to get plateforms datas");
            toast.error("Failed to get ufdii datas");
        }
    },

    getCramerClass: async function(cancel = false){
        try{
            const url = apiUrl + "cramer_class/all";
            const headers = { 'Content-Type': 'application/json' }

            return await this.get(url, headers);
        }catch(ex){
            // throw new Error("Failed to get plateforms datas");
            toast.error("Failed to get Cramer CLASS datas");
        }
    },

    getHumanRouteExposure: async function(cancel = false){
        try{
            const url = apiUrl + "human_route_exposure/all";
            const headers = { 'Content-Type': 'application/json' }

            return await this.get(url, headers);
        }catch(ex){
            // throw new Error("Failed to get plateforms datas");
            toast.error("Failed to get Human Route of Exposure datas");
        }
    },

    getAnimalRouteAdministration: async function(cancel = false){
        try{
            const url = apiUrl + "animal_route_administration/all";
            const headers = { 'Content-Type': 'application/json' }

            return await this.get(url, headers);
        }catch(ex){
            // throw new Error("Failed to get plateforms datas");
            toast.error("Failed to get Animal Route of Administration datas");
        }
    },

    getStudyDuration: async function(cancel = false){
        try{
            const url = apiUrl + "study_duration/all";
            const headers = { 'Content-Type': 'application/json' }

            return await this.get(url, headers);
        }catch(ex){
            // throw new Error("Failed to get plateforms datas");
            toast.error("Failed to get Study Duration datas");
        }
    },

    getStudyTestMethod: async function(cancel = false){
        try{
            const url = apiUrl + "study_test_method/all";
            const headers = { 'Content-Type': 'application/json' }

            return await this.get(url, headers);
        }catch(ex){
            // throw new Error("Failed to get plateforms datas");
            toast.error("Failed to get Study Test Method datas");
        }
    },

    getGLPCompliance: async function(cancel = false){
        try{
            const url = apiUrl + "glp_compliance/all";
            const headers = { 'Content-Type': 'application/json' }

            return await this.get(url, headers);
        }catch(ex){
            // throw new Error("Failed to get plateforms datas");
            toast.error("Failed to get GLP Compliance datas");
        }
    },

    getDoseDescriptor: async function(cancel = false){
        try{
            const url = apiUrl + "dose_descriptor/all";
            const headers = { 'Content-Type': 'application/json' }

            return await this.get(url, headers);
        }catch(ex){
            // throw new Error("Failed to get plateforms datas");
            toast.error("Failed to get Dose Descriptor datas");
        }
    },

    getSeverity: async function(cancel = false){
        try{
            const url = apiUrl + "severity/all";
            const headers = { 'Content-Type': 'application/json' }

            return await this.get(url, headers);
        }catch(ex){
            // throw new Error("Failed to get plateforms datas");
            toast.error("Failed to get Severity datas");
        }
    },

    getUnits: async function(cancel = false){
        try{
            const url = apiUrl + "units/all";
            const headers = { 'Content-Type': 'application/json' }

            return await this.get(url, headers);
        }catch(ex){
            // throw new Error("Failed to get plateforms datas");
            toast.error("Failed to get Units datas");
        }
    },

    getTypeEffects: async function(cancel = false){
        try{
            const url = apiUrl + "type_effects/all";
            const headers = { 'Content-Type': 'application/json' }

            return await this.get(url, headers);
        }catch(ex){
            // throw new Error("Failed to get plateforms datas");
            toast.error("Failed to get Type Effects datas");
        }
    },

    getConsolidatedData: async function(cas, cancel = false){
        try{
            const url = apiUrl + "consolidated/" + cas;
            const headers = { 'Content-Type': 'application/json' }

            return await this.get(url, headers);
        }catch(ex){
            // throw new Error("Failed to get plateforms datas");
            toast.error("Failed to get consolidated datas");
        }
    },
    
    searchByCategory: async function(category){
        try{
            const url = category ? apiUrl + 'search/' + category : apiUrl + 'search/';
            const headers = { 'Content-Type': 'application/json' }

            return await this.get(url, headers);

        }catch(ex){
            // throw new Error("Failed to search data with category={"+category+"}");
            toast.error("Failed to search data with category={"+category+"}");
        }
    },

    // ---------------------------------------------------------------------------------------------

    savePlateform: async function(values, audit_data, cancel = false){
        try{
            const url = apiUrl + 'plateforms/save';
            const headers = { 'Content-Type': 'application/json' };

            // return await this.post(url, headers, JSON.stringify(values)); // FETCH
            return await this.post(url, headers, values, audit_data); // AXIOS
        }catch(ex){
            // throw new Error("Failed to get save plateforms modifications");
            toast.error("Failed to get save plateforms modifications");
        }
    },

    deletePlateform: async function(values, audit_data, cancel = false){
        try{
            const url = apiUrl + 'plateforms/delete';
            const headers = { 'Content-Type': 'application/json' };

            // return await this.post(url, headers, JSON.stringify(values)); // FETCH
            return await this.post(url, headers, values, audit_data); // AXIOS
        }catch(ex){
            // throw new Error("Failed to get save plateforms modifications");
            toast.error("Failed to get save plateforms modifications");
        }
    },

    savePopulationConcerned: async function(values, audit_data, cancel = false){
        try{
            const url = apiUrl + 'population_concerned/save';
            const headers = { 'Content-Type': 'application/json' };

            // return await this.post(url, headers, JSON.stringify(values)); // FETCH
            return await this.post(url, headers, values, audit_data); // AXIOS
        }catch(ex){
            // throw new Error("Failed to save master-datas modifications");
            toast.error("Failed to save population concerned modifications");
        }
    },

    saveAnimals: async function(values, audit_data, cancel = false){
        try{
            const url = apiUrl + 'animals/save';
            const headers = { 'Content-Type': 'application/json' };

            // return await this.post(url, headers, JSON.stringify(values)); // FETCH
            return await this.post(url, headers, values, audit_data); // AXIOS
        }catch(ex){
            // throw new Error("Failed to save master-datas modifications");
            toast.error("Failed to save animals modifications");
        }
    },

    saveUncertaintyFactors: async function(values, audit_data, cancel = false){
        try{
            const url = apiUrl + 'uncertainty_factors/save';
            const headers = { 'Content-Type': 'application/json' };

            // return await this.post(url, headers, JSON.stringify(values)); // FETCH
            return await this.post(url, headers, values, audit_data); // AXIOS
        }catch(ex){
            // throw new Error("Failed to save master-datas modifications");
            toast.error("Failed to save uncertainty factors modifications");
        }
    },

    saveCramerClass: async function(values, audit_data, cancel = false){
        try{
            const url = apiUrl + 'cramer_class/save';
            const headers = { 'Content-Type': 'application/json' };

            // return await this.post(url, headers, JSON.stringify(values)); // FETCH
            return await this.post(url, headers, values, audit_data); // AXIOS
        }catch(ex){
            // throw new Error("Failed to save master-datas modifications");
            toast.error("Failed to save Cramer CLASS modifications");
        }
    },

    saveHumanRouteExposure: async function(values, audit_data, cancel = false){
        try{
            const url = apiUrl + 'human_route_exposure/save';
            const headers = { 'Content-Type': 'application/json' };

            // return await this.post(url, headers, JSON.stringify(values)); // FETCH
            return await this.post(url, headers, values, audit_data); // AXIOS
        }catch(ex){
            // throw new Error("Failed to save master-datas modifications");
            toast.error("Failed to save Human Route of Exposure modifications");
        }
    },

    saveAnimalRouteAdministration: async function(values, audit_data, cancel = false){
        try{
            const url = apiUrl + 'animal_route_administration/save';
            const headers = { 'Content-Type': 'application/json' };

            // return await this.post(url, headers, JSON.stringify(values)); // FETCH
            return await this.post(url, headers, values, audit_data); // AXIOS
        }catch(ex){
            // throw new Error("Failed to save master-datas modifications");
            toast.error("Failed to save Animal Route of Administration modifications");
        }
    },

    saveStatus: async function(values, audit_data, cancel = false){
        try{
            const url = apiUrl + 'compounds/status/save';
            const headers = { 'Content-Type': 'application/json' };

            //return await this.post(url, headers, JSON.stringify(values)); // FETCH
            return await this.post(url, headers, values, audit_data, false);
        }catch(ex){
            //throw new Error("Failed to save data for compound");
            console.log(ex);
            toast.error("Failed to save status for compound");
        }
    },

    saveContains: async function(values, cancel = false){
        try{
            const url = apiUrl + 'compounds/contains/save';
            const headers = { 'Content-Type': 'application/json' };

            //return await this.post(url, headers, JSON.stringify(values)); // FETCH
            return await this.post(url, headers, values, null, false);
        }catch(ex){
            //throw new Error("Failed to save data for compound");
            console.log(ex);
            toast.error("Failed to save data for compound");
        }
    },

    saveFlagged: async function(values, cancel = false){
        try{
            const url = apiUrl + 'flagged/save';
            const headers = { 'Content-Type': 'application/json' };

            //return await this.post(url, headers, JSON.stringify(values)); // FETCH
            return await this.post(url, headers, values, null, false);
        }catch(ex){
            //throw new Error("Failed to save data for compound");
            console.log(ex);
            toast.error("Failed to save data for compound");
        }
    },

    save: async function(values, audit_data, cancel = false){
        try{
            const url = apiUrl + 'compounds/save';
            const headers = { 'Content-Type': 'application/json' };

            //return await this.post(url, headers, JSON.stringify(values)); // FETCH
            return await this.post(url, headers, values, audit_data);
        }catch(ex){
            //throw new Error("Failed to save data for compound");
            console.log(ex);
            toast.error("Failed to save data for compound");
        }
    },

    
    audit: async function(differences, element, author, cancel = false){

        try{
            const url = apiUrl + 'audits/save';
            const headers = { 'Content-Type': 'application/json' };
            const values = {
                date: Date.now(),
                author: author,
                element : element,
                differences: differences
            };

            // return await this.post(url, headers, JSON.stringify(values)); // FETCH
            return await this.post(url, headers, values, null, false); // AXIOS
        }catch(ex){
            // throw new Error("Failed to save audit");
            toast.error("Failed to save audit");
        }
    },

    delete: async function(values, audit_data, cancel = false){
        try{
            const url = apiUrl + 'compounds/delete';
            const headers = { 'Content-Type': 'application/json' };

            //return await this.post(url, headers, JSON.stringify(values)); // FETCH
            return await this.post(url, headers, values, audit_data);
        }catch(ex){
            //throw new Error("Failed to save data for compound");
            console.log(ex);
            toast.error("Failed to save data for compound");
        }
    },

    // ---------------------------------------------------------------------------------------------------------

    // GENERIC
    get: async function(url, headers, triggerAlert = true){

        const config = await CognitoAuth.getHeaders(url, headers);
        const response = await axios.get(url, config).catch(function (error){
            if(triggerAlert){
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                    toast.error("RESPONSE ERROR DURING {GET} : " + url);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    //console.log(error.request);
                    toast.error("REQUEST ERROR DURING {GET} : " + url);

                } else {
                    // Something happened in setting up the request that triggered an Error
                    // console.log('Error', error.message);
                    toast.error("STRANGE ERROR DURING {POST} : " + url + " {" + error.message + "}");
                }
                //console.log(error.config);
            }
        });
        // console.log(response);
        return response.data;

    },

    post: async function(url, headers, body, audit_data, triggerAlert = true){

        const config = await CognitoAuth.getHeaders(url, headers);
        const response = await axios.post(url, body, config)
        .then((res) => {
            if(audit_data != null){
                this.audit(audit_data.differences, audit_data.element, audit_data.user);
            }

            if(triggerAlert){
                toast.success("Save successfully !");
            }
            //console.log(res.data);
            return res.data;
        })
        .catch(function (error) {
            if(triggerAlert){
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                    toast.error("RESPONSE ERROR DURING {POST} : " + url);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    //onsole.log(error.request);
                    toast.error("REQUEST ERROR DURING {POST} : " + url);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    //console.log('Error', error.message);
                    toast.error("STRANGE ERROR DURING {POST} : " + url + " {" + error.message + "}");
                }
                // console.log(error.config);
            }
        });

        //console.log(response);
        return response;
    },
}