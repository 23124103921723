import './search.css';
import { API } from "./../../services/api/api";
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect } from 'react';
import { Card, CardHeader, CardContent, Chip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from '@mui/icons-material/Search';
import { Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const getChipColor = (type, status) => {
    return type.find(x => x.name === status)?.color;
};

export default function Search({ onSelect }) {
    const navigate = useNavigate();

    const [rows, setRows] = React.useState([]);
    const [status, setStatus] = React.useState([]);
    const [w_status, setWorkflowStatus] = React.useState([]);
    const [loading, setLoading] = React.useState(true); // Add loading state
    const { category } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await API.searchByCategory(category);
                const mapped = result.map(x => ({
                    _key: x._key,
                    cas: x.cas,
                    name: x.name,
                    categories: (x.categories).toString(),
                    date: x.date,
                    author: x.author,
                    workflow_status: x.workflow_status,
                    approval_status: x.approval_status,
                    status: x.edit.edit ? `in modification by "${x.edit.user}"` : "available"
                })).sort((a, b) => a.name.localeCompare(b.name));
                setRows(mapped);
            } catch {
                toast.warn("WARNING! Impossible to fetch data from category!");
            } finally {
                setLoading(false); // Set loading to false after data is fetched
            }
        };

        const fetchDataStatus = async () => {
            try {
                const result = await API.getStatus("approval");
                const map = result;
                setStatus(map);
            } catch (ex) {
                toast.warn("WARNING! Impossible to parse status!");
            }
        };

        const fetchWorkflowStatus = async () => {
            try {
                const result = await API.getStatus("workflow");
                const map = result;
                setWorkflowStatus(map);
            } catch (ex) {
                toast.warn("WARNING! Impossible to parse status!");
            }
        };

        fetchData();
        fetchDataStatus();
        fetchWorkflowStatus();
    }, [category]);

    const handleRowClick = async (params) => {
        const substance = await API.getPropertyData("_key", params.row._key);
        onSelect(substance[0]);
        navigate("/ids/" + substance[0]._key);
    };

    const getWorkflowLevel = (status) => {
        const workflow = w_status.find(x => x.name === status);
        return workflow ? workflow.level : 0;
    };

    const columns = [
        {
            field: 'cas',
            headerName: 'CAS Number',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            editable: false,
        },
        {
            field: 'name',
            headerName: 'Name',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            editable: false,
        },
        {
            field: 'categories',
            headerName: 'Categories',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            editable: false,
        },
        {
            field: 'workflow_status',
            headerName: 'Workflow',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            renderCell: (params) => (
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    {params.value ? <Chip label={params.value} style={{ backgroundColor: getChipColor(w_status, params.value), color: '#FFFFFF' }} /> : '-'}
                </div>
            ),
            flex: 1,
            editable: false,
            sortComparator: (v1, v2) => getWorkflowLevel(v1) - getWorkflowLevel(v2), 
        },
        {
            field: 'approval_status',
            headerName: 'Approval',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            renderCell: (params) => (
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    {params.value ? <Chip label={params.value} style={{ backgroundColor: getChipColor(status, params.value), color: '#FFFFFF' }} /> : '-'}
                </div>
            ),
            flex: 1,
            editable: false,
        },
        {
            field: 'status',
            headerName: 'Status',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            editable: false,
        },
    ];

    return (
        <Box sx={{ '& .super-app-theme--header': { backgroundColor: 'rgba(207,222,208, 0.55)' } }}>
            <Card>
                <CardHeader
                    avatar={<SearchIcon sx={{ width: '50px', height: '50px' }} />}
                    title={<Typography style={{ textAlign: 'left' }} variant="h5">Search...<>{category ? (<Typography>(filter by "{category}")</Typography>) : null}</> </Typography>}
                />
                <CardContent>
                    <DataGrid
                        getRowId={(row) => row._key}
                        rows={rows}
                        onRowClick={handleRowClick}
                        autoHeight
                        columns={columns}
                        loading={loading}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        pageSizeOptions={[10]}
                        disableRowSelectionOnClick
                    />
                </CardContent>
            </Card>
        </Box>
    );
}
