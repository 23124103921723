import React, { useEffect, useState } from 'react';
import { Avatar, CircularProgress, Box, Typography, Badge } from '@mui/material';

const CustomAvatar = ({ alt, src, progress }) => {

    return (
        <Box position="relative" display="inline-flex" m={1}>
            <CircularProgress variant="determinate" value={progress} size={85} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
            >
                <Avatar style={{ width: '75px', height: '75px' }} src={src} alt={alt} >?</Avatar>
            </Box>
            <Badge
                badgeContent={`${Math.round(progress)}%`}
                color="primary"
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    transform: 'translate(50%, 50%)',
                    '& .MuiBadge-badge': {
                        fontSize: '0.75rem',
                        padding: '0.25em 0.5em',
                    },
                }}
            />
        </Box>
    );
};

export default CustomAvatar;
