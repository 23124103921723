import * as React from 'react';
import { useEffect } from 'react';
import './drawer.css';
import { styled } from '@mui/material/styles';
import { makeStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
// import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import SummarizeIcon from '@mui/icons-material/Summarize';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import QueuePlayNextIcon from '@mui/icons-material/QueuePlayNext';
import { IconButton } from '@mui/material';
import { getEmptyCompound, isObjectEmpty, isNullUndefinedEmpty } from "./../utils/utils";

// import {createMemoryHistory} from 'history';
import { Routes, Route, Link, useLocation } from "react-router-dom";

import Home from './../pages/home/home.js'
import Parameters from './../pages/parameters/parameters.js'
import IDs from '../pages/ids/ids.js';
import AutoCompleter from '../component/autocomplete.js';

import logo from './../assets/LOGO3.png';
import Search from '../pages/search/search.js';
import User from '../pages/user/user.js';
import Libraries from '../pages/libraries/libraries';
import { CognitoAuth } from '../auth/cognito';
import Report from '../pages/report/report';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderTree } from '@fortawesome/free-solid-svg-icons';

const drawerWidth = 16;

const useStyles = makeStyles((theme) => ({
  drawerPaper: { width: 'inherit' },
  link: {
    textDecoration: 'none',
    color: 'black'
  },
  active: {
    backgroundColor: '#7E9A7E',
  },
}))


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}%`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}%)`,
    marginLeft: `${drawerWidth}%`,
    background: "#8cac8c",
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0.2, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

export default function LeftDrawer({ onValue }) {
  // const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [data, setData] = React.useState({});
  const [user, setUser] = React.useState({});
  const location = useLocation();
  const classes = useStyles();

  useEffect(() => {
    const fetchUser = async () => {
      const result = await CognitoAuth.getCurrentUser();
      setUser(result);
    };

    fetchUser();
  }, []);


  // const handleDrawerOpen = () => {
  //   setOpen(!open);
  // };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  const handleSelect = (value) => {
    //console.log(`User selected [DASHBOARD]: ${value}`);
    onValue(value);
    setData(value);
  };

  const handleSubmit = (value) => {
    //console.log(`User values submitted [DRAWER]: ${JSON.stringify(value, null, 2)}`);
    setData(value);
  }

  const isActive = (path) => {
    //console.log(location.pathname);
    if (path !== '/') {
      return location.pathname.includes(path);
    } else {
      return location.pathname === path;
    }
  };

  // const history = createMemoryHistory();

  return (
    // <Router location={history.location} navigator={history}>
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} anchor="center">
        <Toolbar sx={{ justifyContent: "space-between" }}>
          {/* <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton> */}
          {/* <Typography variant="h6" noWrap component="div">
              ToxDB
            </Typography>  */}
          <AutoCompleter onSelect={handleSelect} />

          <Link to="/profile" style={{ color: 'inherit', textDecoration: 'inherit' }}>
            <MenuItem sx={{ color: "#000000" }}>
              <Typography sx={{ m: 2 }} variant="h8">{user?.signInUserSession?.idToken?.payload?.name}</Typography>
              <AccountCircle />
            </MenuItem>
          </Link>
        </Toolbar>
      </AppBar>

      <Drawer
        PaperProps={{
          sx: {
            backgroundColor: "#acc4ac"
          }
        }}
        sx={{
          width: `${drawerWidth}%`,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: `${drawerWidth}%`,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <Link to={'/'} style={{ color: 'inherit', textDecoration: 'inherit' }}>
          <DrawerHeader>

            <img src={logo} width="50" height="50" alt="" />
            <Typography sx={{ ml: 2 }} variant="h5">SILVER - {process.env.REACT_APP_VERSION} <span className="notation">({process.env.REACT_APP_ENVIRONMENT}) {process.env.REACT_APP_BUILD}</span></Typography>
            {/* <IconButton onClick={handleDrawerClose}>
                  {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton> */}
          </DrawerHeader>
        </Link>
        <Divider />
        <List>
          <Link to="/" className={classes.link}>
            <ListItem disableGutters={true} className={isActive('/') ? classes.active : ''}>
              <ListItemButton>
                <IconButton aria-label="home">
                  <HomeOutlinedIcon />
                </IconButton>
                <ListItemText primary={"Home"} />
              </ListItemButton>
            </ListItem>
          </Link>
          <Link to="/search" className={classes.link}>
            <ListItem disableGutters={true} className={isActive('/ids') ? classes.active : ''}>
              <ListItemButton>
                <IconButton aria-label="chem">
                  <FontAwesomeIcon icon={faFolderTree} size="xs" style={{ marginRight: '3px' }} />
                </IconButton>
                <ListItemText primary={"Database"} />
              </ListItemButton>
            </ListItem>
          </Link>
          <Link to="/parameters" className={classes.link}>
            <ListItem disableGutters={true} className={isActive('/parameter') ? classes.active : ''}>
              <ListItemButton>
                <IconButton aria-label="parameters">
                  <HandymanOutlinedIcon />
                </IconButton>
                <ListItemText primary={"Parameters"} />
              </ListItemButton>
            </ListItem>
          </Link>
          <Link to="/report" className={classes.link}>
            <ListItem disableGutters={true} className={isActive('/report') ? classes.active : ''}>
              <ListItemButton>
                <IconButton aria-label="report">
                  <SummarizeIcon />
                </IconButton>
                <ListItemText primary={"Report"} />
              </ListItemButton>
            </ListItem>
          </Link>
          <Link to="/libraries" className={classes.link}>
            <ListItem disableGutters={true} className={isActive('/libraries') ? classes.active : ''}>
              <ListItemButton>
                <IconButton aria-label="lib">
                  <MenuBookOutlinedIcon />
                </IconButton>
                <ListItemText primary={"Library"} />
              </ListItemButton>
            </ListItem>
          </Link>
          <Link to="/profile" className={classes.link}>
            <ListItem disableGutters={true} className={isActive('/profile') ? classes.active : ''}>
              <ListItemButton>
                <IconButton aria-label="profile">
                  <PersonOutlineOutlinedIcon />
                </IconButton>
                <ListItemText primary={"User Profile"} />
              </ListItemButton>
            </ListItem>
          </Link>
          {/* <Link to={process.env.REACT_APP_BAWA_SILVER_PLUS} className={classes.link} target="_blank">
                <ListItem disableGutters={true}>
                  <ListItemButton>   
                    <IconButton aria-label="silver+">
                      <QueuePlayNextIcon />
                    </IconButton>             
                    <ListItemText primary={"SILVER+"} />
                  </ListItemButton>
                </ListItem>
              </Link> */}
        </List>
      </Drawer>

      <Main open={open}>
        <DrawerHeader />
        <Box>
          <Routes>
            {/* <Route path='/' element={<Home/>} /> */}
            <Route path='/' element={<Home user={user} onSelect={handleSelect} />} />
            <Route path='/ids/:key?' element={<IDs handleData={!isObjectEmpty(data) ? data : getEmptyCompound()} user={user} passSubmit={handleSubmit} />} />
            <Route path='/search/:category?' element={<Search onSelect={handleSelect} />} />
            <Route path='/parameters' element={<Parameters user={user} />} />
            <Route path='/report' element={<Report user={user} />} />
            <Route path='/libraries' element={<Libraries />} />
            <Route path='/profile' element={<User user={user} />} />
          </Routes>
        </Box>

      </Main>
    </Box>
    // </Router>
  );
}